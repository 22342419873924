import React from 'react'
import dote from '../Assets/Landing/dote.png'
import FeatureCard from '../Component/FeatureCard'
import elips3 from '../Assets/Landing/elips5.png'
import { motion,useScroll, useTransform } from "framer-motion";
export default function Feature() {
  const { scrollYProgress } = useScroll()
const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);
 
    const CardList=[
{mt:'',title: <>City <br></br> Information</> ,deatile:'Provide helpful information about the city where the campus is located.',icon:<svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
<path d="M36.75 21.125H33.5V24.375H36.75V21.125ZM40 21.125H43.25V24.375H40V21.125ZM49.75 21.125H46.5V24.375H49.75V21.125ZM33.5 27.625H36.75V30.875H33.5V27.625ZM43.25 27.625H40V30.875H43.25V27.625ZM46.5 27.625H49.75V30.875H46.5V27.625ZM36.75 34.125H33.5V37.375H36.75V34.125ZM40 34.125H43.25V37.375H40V34.125ZM49.75 34.125H46.5V37.375H49.75V34.125ZM33.5 40.625H36.75V43.875H33.5V40.625ZM43.25 40.625H40V43.875H43.25V40.625ZM33.5 47.125H36.75V50.375H33.5V47.125ZM43.25 47.125H40V50.375H43.25V47.125ZM33.5 53.625H36.75V56.875H33.5V53.625ZM43.25 53.625H40V56.875H43.25V53.625ZM33.5 60.125H36.75V63.375H33.5V60.125ZM43.25 60.125H40V63.375H43.25V60.125ZM51.375 48.75H56.25V45.5H51.375V48.75ZM56.25 55.25H51.375V52H56.25V55.25ZM51.375 61.75H56.25V58.5H51.375V61.75Z" fill="#122F63"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.625 6.5L54.625 16.25V39H61.125C61.556 39 61.9693 39.1712 62.274 39.476C62.5788 39.7807 62.75 40.194 62.75 40.625V68.25H64.375C64.806 68.25 65.2193 68.4212 65.524 68.726C65.8288 69.0307 66 69.444 66 69.875C66 70.306 65.8288 70.7193 65.524 71.024C65.2193 71.3288 64.806 71.5 64.375 71.5H15.625C15.194 71.5 14.7807 71.3288 14.476 71.024C14.1712 70.7193 14 70.306 14 69.875C14 69.444 14.1712 69.0307 14.476 68.726C14.7807 68.4212 15.194 68.25 15.625 68.25H17.25V34.125C17.25 33.694 17.4212 33.2807 17.726 32.976C18.0307 32.6712 18.444 32.5 18.875 32.5H22.125V21.125H25.375V32.5H28.625V6.5ZM31.875 11.1898L51.375 18.5022V39H46.5C46.069 39 45.6557 39.1712 45.351 39.476C45.0462 39.7807 44.875 40.194 44.875 40.625V68.25H31.875V11.1898ZM20.5 35.75V68.25H28.625V35.75H20.5ZM59.5 68.25H56.25V65H51.375V68.25H48.125V42.25H59.5V68.25Z" fill="#122F63"/>
</svg>,color:'bg-[#F1F7FF]'},
{mt:'mt-[-140px]',title:<>Campus <br></br> Details</>,deatile:'A great resource that offers useful details about the Campus.',icon:<svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
<path d="M39 9.75L7.3125 17.0625V21.9375L39 14.625L70.6875 21.9375V17.0625L39 9.75ZM24.375 24.375C16.5092 24.375 8.83594 27.5754 8.83594 27.5754L7.3125 28.2579V65.8125H34.8099C35.6582 67.2701 37.1987 68.25 39 68.25C40.7989 68.25 42.3418 67.2701 43.1901 65.8125H70.6875V28.2604L69.1641 27.5754C69.1641 27.5754 61.4908 24.375 53.625 24.375C46.5124 24.375 40.2675 26.7638 39 27.2708C37.7325 26.7638 31.4876 24.375 24.375 24.375ZM24.375 29.25C29.0891 29.25 34.125 30.7734 36.5625 31.6119V58.7291C33.8496 57.8346 29.3938 56.5963 24.375 56.5963C19.2514 56.5963 15.0808 57.7761 12.1875 58.7291V31.6119C14.0644 30.9319 19.0125 29.25 24.375 29.25ZM53.625 29.25C58.9875 29.25 63.9356 30.9343 65.8125 31.6119V58.7291C62.9168 57.7785 58.7486 56.5963 53.625 56.5963C48.6062 56.5963 44.1504 57.8346 41.4375 58.7291V31.6119C43.875 30.7734 48.9109 29.25 53.625 29.25Z" fill="#FF9E00"/>
</svg>,color:'bg-[#FFF7E3]'},
{mt:'',title:<>Fellowship <br></br> Information</>,
deatile:'Provide details on various fellowships available to students.',icon:<svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
<path d="M3.28819 2.74219L26.0538 25.5078V26.0538H19.2456L2.74219 9.42033V13.3138L15.3829 26.0538H2.74219V30.9288H30.9288V2.74219H26.0538V15.7578L13.0382 2.74219H3.28819ZM46.8672 2.74219V30.9288H75.2578V26.0538H62.0382L75.2578 12.834V3.08405L52.2882 26.0538H51.7422V19.2456L68.3757 2.74219H64.4824L51.7423 15.3829V2.74219H46.8672ZM2.74219 47.1189V51.9939H15.7578L2.74219 65.0097V74.7597L25.5078 51.9939H26.0538V58.8021L9.46847 75.2578H13.3616L26.0538 62.6648V75.2578H30.9288V47.1189H2.74219ZM46.867 47.1189V75.2578H51.742V62.2899L64.7103 75.2578H74.4603L51.742 52.5399V51.9939H58.5503L75.2578 68.8329V64.9396L62.4131 51.9939H75.2578V47.1189H46.867Z" fill="#86345A"/>
</svg>,color:'bg-[#FFF2F8]'},
{mt:'mt-[-140px]',title:<>Become a <br></br> Member</>,deatile:'Registration for fellowships on your campus is available.',icon:<svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
<path d="M58.5 34.125V19.5L51.6425 22.945C52.1055 24.8576 52.1283 26.8503 51.7089 28.7729C51.2895 30.6956 50.4391 32.4978 49.2217 34.0439C48.0042 35.5899 46.4516 36.8393 44.681 37.6978C42.9103 38.5564 40.9678 39.0016 39 39C37.0322 39.0016 35.0897 38.5564 33.319 37.6978C31.5484 36.8393 29.9958 35.5899 28.7783 34.0439C27.5609 32.4978 26.7105 30.6956 26.2911 28.7729C25.8717 26.8503 25.8945 24.8576 26.3575 22.945L16.25 17.875L39 6.5L61.75 17.875V34.125H58.5ZM39 29.25L32.5 26C32.5 29.575 35.425 32.5 39 32.5C42.575 32.5 45.5 29.575 45.5 26L39 29.25ZM47.9375 17.615L39.52 13.325L30.7775 17.7775L39.2275 22.0675L47.9375 17.615ZM39 42.25C47.6775 42.25 65 46.5725 65 55.25V65H13V55.25C13 46.5725 30.3225 42.25 39 42.25ZM39 48.425C29.25 48.425 19.175 53.17 19.175 55.25V58.825H58.825V55.25C58.825 53.17 48.6525 48.425 39 48.425Z" fill="#00DA71"/>
</svg>,color:'bg-[#DEFFEE]'},

    ]
  return (
    <section className='overflow-hidden' id='Feature'>
        <div>
            <p className=' mt-[20px] text-center text-[24px] Poppins Popp-900 font-bold'>myfellow Features</p>
    <div className='flex items-center md:justify-center  gap-[91px] mt-[92px]'>
      {/* left side */}
       <div className='hidden md:hidden lg:block '>
<div style={{
  background:`rgba(107, 23, 104, 0.29)`
}} className='h-[178px] ml-[82px] w-[178px] rounded-tl-[100px] '></div>
<div className='mt-[-115px] ml-[120px]'>
<p className='w-[358px] text-[40px] lg:text-[40px] xl:text-[40px] Poppins Popp-600  '>How can we help you?</p>
<p className='w-[396px] Poppins Popp-400 text-[16px] leading-[160%] text-[#565656] '>myfellow provides a range of basic features to enhance your experience.</p>
</div>
<img className='mt-[104px] ml-[125px] lg:w-[355px]' src={dote} alt="" />
       </div>
       {/* right side */}
       <motion.div
        
       className='grid  grid-cols-2 gap-[20px]  ml-[15px] md:ml-0 lg:ml-0 md:gap-[34px] lg:gap-[34px] justify-center items-center '>
        {CardList.map((data,index)=>(
            <FeatureCard
            mt={data.mt} key={index} title={data.title} deatile={data.deatile} icon={data.icon} color={data.color}/>
        ))}
<div>

</div>
<div 
 
className='flex justify-end mr-[-46px]   -z-10 mt-[-220px] lg:mt-[-190px] '>
<img src={elips3} alt="" />
</div>

       </motion.div>

    </div>
        </div>
    </section>
  )
}
