import React from 'react'
import habesha from '../Assets/Landing/habsha.png'
import HabeshaCard from '../Component/HabeshaCard'
import h1 from '../Assets/Landing/h1.png'
import h2 from '../Assets/Landing/h2.png'
import h3 from '../Assets/Landing/h3.png'
import {  useInView } from 'framer-motion'
import { useRef } from 'react'
export default function Habesha() {
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});
  return (
    <div ref={ref} className=' overflow-hidden'>
      <a href="https://www.habeshastudent.com/" target="_blank" rel="noopener noreferrer">
 <img className='lg:w-auto w-[100vw] lg:h-auto  mt-[97px] scale-150 ml-[25px] lg:ml-0 lg:scale-100 ' src={habesha} alt="" />
 </a>   <div  className='mt-[40px] flex flex-wrap gap-4   lg:flex justify-center lg:gap-[20px]'>

<a href="https://www.habeshastudent.com/a/purpose.html" target="_blank" rel="noopener noreferrer">
<HabeshaCard title='የሕይወቴ ዓላማ ምንድነው?' deatile='በሕይወት ውስጥ ይበልጥ ልትረዳቸው የሚገቡ የሕይወት ትርጉሞች አሉ።' image={h1}/>
</a>
<a href=" https://www.habeshastudent.com/a/prayers.html" target="_blank" rel="noopener noreferrer">
<HabeshaCard title='እግዚአብሔር ፀሎት ይመልሳልን?' deatile='ምላሽ ማግኘት የሚችል ፀሎት እንዴት መፀለይ እንችላለን?' image={h2}/>
</a>
<a className='md:hidden hidden lg:block' href=" https://www.habeshastudent.com/a/personally.html" target="_blank" rel="noopener noreferrer">
<HabeshaCard isView={isView} title='እግዚአብሔርን በግል ማወቅ' deatile='በግልህ ከእግዚአብሔር ጋር ግንኙነት እንዴት መጀመር እንደምትችል' image={h3}/>
</a>
        </div>
        <div className='mt-[39px] flex md:mb-1 justify-center items-center'>
            <a target='_black' href='https://www.habeshastudent.com' className=' lg:mb-5 md:mb-5 mb-8 flex items-center justify-center h-[64px] z-[2] text-center  w-[257px] rounded-[39px]  px-[12px] text-[24px] Poppins Popp-600 leading-[40px]  text-white Visitwebsite '
            
            ><p className='Poppins Popp-600'>Visit Website</p></a>
        </div>
    </div>
  )
}
