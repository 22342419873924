import React from 'react'
import {  useInView } from "framer-motion";
import { useRef } from 'react';
export default function HabeshaCard(props) {
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-1% 0px -1% 0px'});
  
  return (
    <div
   ref={ref}
    className={ `${isView?'translation-all duration-500 scale-500':'scale-0'} rounded-[20px] overflow-hidden odd:justify-start bg-white  w-[300px] h-[450px] lg:w-[369px] lg:h-[518px]   hover:from-[#da23ff]  hover:to-[#0c34d3] `}
    style={{
        boxShadow:'0px 4px 20px 0px rgba(0, 0, 0, 0.10)',

    }}
    >
        <img src={props.image} alt="" />
        <div className='flex flex-col mt-[30px] gap-[15px]  justify-center items-center '>

   <p  className='w-[209px] h-[39px] text-center text-[24px]  first:text-[#093F68] Abyssinica Aby-400 '>{props.title}</p>
   <p  className='w-[280px] mt-[30px] h-[55px] text-[20px] text-[#52525B]  text-center Abyssinica Aby-400' >{props.deatile}</p>
   
        </div>
    </div>
  )
}
