import {BASE_URL} from '../Page/Url'
export default function Card(props){
  
    return(
    
        <a   target='_blank' href={props.data.url} key={props.id} className=" allcampCard w-[360px] h-[349px] md:w-[384px] md:h-[403px] lg:w-[384px] lg:h-[403px]">
            <img className={` object-cover ${props.image}`} src={`${BASE_URL}/${props.data.image}`}/>
            <div className="mx-[15px] my-[16px] md:mx-[15px] md:my-[32px] lg:mx-[15px] lg:my-[32px] flex-col gap-7 md:gap-[12px] lg:gap-[12px]">
                <p className="text-[#6B1768] text-[20px] md:text-[24px] lg:text-[24px] Inter Int-600 ">{props?.data?.universityName}</p>
                <div className="flex justify-between">
                    <p className="text-[13px] md:text-[14px] lg:text-[14px] Inter Int-600">{props?.data?.campusName}</p>
                    <p className='Searchfname px-2 text-[14px] md:text-[12px] lg:text-[12px] xl:text-[12px] '>{props?.data.fellowshipName}</p>
        
                </div>
            </div>
        </a>
      
    )
 }