import React, { useEffect, useState } from "react";

import Image from "../Component/Image";

import meso1 from "../Assets/meso1.png";

export default function Login(){
  const [formData,setFormData]=useState({
    userName:'you',
    Password:null,
    resetPassword:false,
  });
  const [isValid, setIsValid] = useState(false);
  const [resetP,setReset]=useState(false);
function handleSubmit(e){
   e.preventDefault();
setFormData({...formData,[e.target.name]:e.target.value})
  // Regular expression to check for at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8 characters
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  setIsValid(passwordRegex.test(formData.Password));
}
function post(){

}
function  reset(){
  setReset(true)
}
function resetBack(){
  setReset(false)
}
useEffect(()=>{

  
},[])
 
  const inputStyle =
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ";
  return (
    <div>
      <div className=" flex h-full w-full ">
        <div className=" w-[50%] h-screen bg-black">
          <Image id="image" className='h-screen w-[100%]'  image={meso1} />
        </div>
        <div className="flex  w-[50%] items-center  ">
            <div className="w-full m-32 ">
            <p className="text-5xl text-center ">JUST LOGIN</p>
          <div className="flex flex-col ">
            <p className="text-sm">user Name</p>
            <input
              className={inputStyle}
              type="email"
             
              placeholder="User Name"
              name="userName"
              onChange={(e)=>handleSubmit(e)}
            />
           {resetP===false? <>
           <p className="text-sm">Password</p>
            <input
              className={inputStyle}
           
             
              onChange={(e)=>handleSubmit(e)}
              type="password"
              placeholder="password"
              name="Password"
              required
            />
              {!isValid && <p className="text-sm opacity-70">Password must be at least <span className="text-red-500">8 characters</span> long and contain a combination of <span className="text-red-500">uppercase letters</span>,<span className="text-red-500">lowercase letters</span>, <span className="text-red-500">numbers</span>, and <span className="text-red-500">special characters</span></p>}
              <button
            className="bg-[#1C355B] text-white rounded-md w-full mt-4 py-2"
            name="Login"
            onClick={post}
          > Login</button>
          </> :
          <>
           <button
           className="bg-[#1C355B] text-white rounded-md w-full mt-4 py-2"
           name="Login"
           onClick={post}
         >Reset Password</button>
         
         </>
          }
          </div>
        
          <div className="flex justify-between   mt-2">
            <span className="text-sm"> <input type='checkbox'/> Remember me? {formData.resetPassword}</span>
            {resetP===true?  <button className="text-sm"        onClick={resetBack}>if you have account Login </button >
            :  <button className="text-sm"        onClick={reset}>forget password </button >
          } 
          
          </div>
            </div>
        
        </div>
      </div>
    </div>
  );
};
