import React, { useEffect, useRef, useState } from 'react'
import image1 from '../Assets/Landing/manland.png'
import image2 from '../Assets/Landing/image2.png'
import smc1 from '../Assets/Landing/smc1.png'
import logo192 from '../Assets/logo512w.png'
import Objective from './Objective';
import Feature from './Feature';
import Product from './Product';
import Habesha from './Habesha';
import LandFooter from './LandFooter';
import { useInView } from 'framer-motion';
import SearchList from '../Component/SearchList';
import axios from 'axios';
import { BASE_URL } from './Url';
import { Link, useLocation, useParams } from 'react-router-dom';

export default function Landing() {

  const ref = useRef(null);
  const isView =useInView(ref,{margin:'-50% 0px -50% 0px'});
    const [active,setActive]=useState(1);
    const [scroll, setScroll] = useState(false);
const [open ,setOpen]=useState(false);
const url=`${BASE_URL}/api/search-campus?`;
const DATA={
  Nav:[
  {List:'Home',hrf:'#Home'},
  {List:'Objective',hrf:'#Objective'},
  {List:'Feature',hrf:'#Feature'},
  {List:'Product',hrf:'#Product'},
 
  ]
}
const [searchKey,SetsearchKey]=useState('');
const [form,setForm] =useState();

const [comingSoon,setComingSoon]=useState([ {
  id: 18,
  campusName: "snfce",
  cityName: "Addis Ababa",
  fellowshipAcronym: "5KECSF",
  fellowshipName: "5 Kilo Evangelical Christian Students Fellowship",
  image: "welcome/8WvIz9r0bXXsaoq6V0KJUBSe5gOjHk7KOElY0pX4.jpg",
  universityName: "Senay University",
  coming:'true',
  url: "https://myfellow.et/coming-soon",
}])
//const combinedArray=comingSoon&& [...comingSoon,'searchResults'[form]];
// useEffect(()=>{
//   combinedArray =
// },form)
const Handle=(e)=> {
  SetsearchKey(e.target.value);
}
useEffect(() => {
  const handleScroll = () => {
    setScroll(window.pageYOffset > 0);
  };
  window.addEventListener('scroll',handleScroll);
  return () => {
    window.removeEventListener('scroll',handleScroll);
  };
}, []);


function search(){

  axios.post(url,
  {
    searchKey:searchKey
  })
  .then(res=>{
   const data = res.data;

///setForm(res?.data)
//setComingSoon(res?.data?.searchResult)
   if(res.data?.status===200){
    // console.log(res?.data?.searchResult)
     //console.log(combinedArray,"statke")
  setForm(res?.data?.searchResult)
   }else{

   }
  }).catch(error=>{
  
  })
}

  return (
<section id='Home'>    
<div
style={{
   backgroundImage: `url(${image1})`,
backgroundPosition: 'center',
 backgroundSize:'cover',
   backgroundRepeat:'no-repeat',
   
}}
className=' h-[1028px] '>
  <div className='relative landGradent h-full  '>
    <div  className='absolute  z-[2] h-[1028px] w-full'>
{/* starting nav */}
<div  className={`w-[100vw] md:w-full lg:w-full  top-0 z-5 `}>
<div className={`w-[100vw] lg:w-full  top-0 z-50 flex flex-shirnk justify-between px-[5%]  text-white ${open===true?'items-start':'items-center'} `}>
<img className=' h-[60px] w-auto py-2 ' src={logo192}/>
<div>   
    <ul className='flex gap-[48px]  hidden md:block md:flex lg:block lg:flex lg:items-center lg:gap-[48px]  '>
        <li onClick={()=>setActive(1)} className={`${active===1?'first:text-[#6B1768] first:font-bold Poppins  Popp-900':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}> <a className={`cursor-pointer Poppins  ${active===1?'Popp-900':'Popp-500'} `} href='#Home'>Home</a> </li>
        <li onClick={()=>setActive(2)} className={`${active===2?'text-[#6B1768] font-bold Poppins Popp-900 ':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}>  <a className={`cursor-pointer Poppins  ${active===2?'Popp-900':'Popp-500'}`}  href='#Objective'>Objective</a> </li>
        <li onClick={()=>setActive(3)} className={`${active===3?'text-[#6B1768] font-bold Poppins Popp-900 ':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}> <a className={`cursor-pointer Poppins  ${active===3?'Popp-900':'Popp-500'}`} href='#Feature'>Features</a> </li>
        <li onClick={()=>setActive(4)} className={`${active===4?'text-[#6B1768] font-bold Poppins Popp-900 ':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}> <a className={`cursor-pointer Poppins  ${active===4?'Popp-900':'Popp-500'}`} href='#Product'>Products</a> </li>
        <li onClick={()=>setActive(5)} className={`flex gap-5 items-center justify-center ${active===5?'text-[#6B1768] font-bold Poppins Popp-900 ':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}>
           <a className={`cursor-pointer Poppins lg:h-[30px] md:h-[30px] h-[30px] rounded-[50px] w-[100px] md:w-[144px] lg:w-[144px] text-[#CFCFCF] flex justify-center items-center bg-gradient-to-b from-[#5B0F58] to-[#C31091]  ${active===5?'Popp-900':'Popp-500'}`}  target='_black' href='https://admin.myfellow.et/signup'>Create</a> 
        <a href='https://admin.myfellow.et' target='_black' className='flex cursor-pointer gap-2 items-center  '>
        <p className='text-[#fff] text-[12px] underline  '>Login</p>
        </a> </li>
   
    </ul> 
    <div className="flex flex-col justify-end items-end gap-4 duration-300 overflow-hidden ">
<svg onClick={()=>setOpen(!open)} className="block md:hidden  mt-2" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
{open===false?
<path d="M0 0.609741H24V2.67287H0V0.609741ZM0 19.1779H24V21.241H0V19.1779ZM0 6.79913H24V8.86225H0V6.79913ZM0 12.9885H24V15.0516H0V12.9885Z" fill="white"/>
:<>
<path d="M4.92856 6.34317L17.6565 19.0711L19.0707 17.6569L6.34277 4.92896L4.92856 6.34317Z" fill="white"/>
<path d="M6.34292 19.071L19.0708 6.34303L17.6566 4.92882L4.92871 17.6567L6.34292 19.071Z" fill="white"/>

</>

}


</svg>

<div className={` flex flex-col gap-4 items-end block lg:hidden duration-300 overflow-hidden  ${open===true?'transtion-x-0 duration-1000 ':'translate-x-full h-0  duration-300'}`}>
{DATA.Nav?.map((data,index)=>(

<div onClick={()=>setOpen(!open)} key={index} className=''>
<a className="cursor-pointer " href={data.hrf}>
<p className=''>{data.List} </p>

</a>

</div>

))}
<a className={`cursor-pointer Poppins lg:h-[30px] md:h-[30px] h-[30px] rounded-[50px] w-[100px] md:w-[144px] lg:w-[144px] text-[#CFCFCF] flex justify-center items-center bg-gradient-to-b from-[#5B0F58] to-[#C31091]  ${active===5?'Popp-900':'Popp-500'}`}  target='_black' href='https://admin.myfellow.et/signup'>Create</a>
<a href='https://admin.myfellow.et' target='_black' className='flex cursor-pointer gap-2 items-center  '>
        <p className='text-[#fff] text-[12px] underline  '>Login</p>
        </a>
</div>

</div>
</div>

</div>

</div>
{/* end of nav */}
<div ref={ref} className=' flex flex-col justify-center items-center mt-[350px] md:mt-[350px] lg:mt-[340px]'>
        <p className={`${isView?'scale-100':'scale-75'} transform-all duration-300 text-center lg:text-[80px] text-[30px] md:text-[44px] Popp-900 md:leading-[80px] lg:leading-[80px] tracking-[-0.5px] text-white Poppins`}>Looking for Christian  <br/> 
        Fellowships on your <span className={`${isView?'scale-100':'scale-50'} transform-all duration-1000 text-[#AF1086] text-[30px] md:text-[44px] lg:text-[80px] Poppins`} >Campus?</span> </p>
<p className={`${isView?'scale-100':'scale-75'} Poppins transform-all duration-1000 mt-[49px] text-center text-white text-[20px] lg:text-[24px] Popp-400 leading-[122%]`}>Try searching your campus here to join a community of <br/> like-minded individuals for spiritual growth.</p>
<div className='bg-[#ffffff3f] mt-[44px] rounded-[76px] '>
  
<div 
style={{
boxShadow:'0px 4px 24px -1px rgba(0, 0, 0, 0.25)'
}}
className={`    w-[350px] md:w-[417px] lg:w-[417px] transform-all duration-1000 Int-400  flex bg-gradient-to-tb backdrop-blur-[20px] from-[#fff] to-[#fff]   items-center rounded-[76px]  h-[60px] justify-between px-1 gap-2  text-white`}>
<input x-webkit-speech autoComplete="off" onKeyUp={()=>search()} onKeyDown={()=>search()} name='search' onChange={(e)=>SetsearchKey(e.target.value)} style={{
 backgroundColor: 'transparent',

}} type='text' className={`searchAll Int-400 Inter  duration-1000 first:ml-[35px] Int-400 bg-transparent text-[#CFCFCF]  outline-none placeholder-[#CFCFCF]`} placeholder='campus Name' />
<div onClick={()=>search()} className='  lg:h-[49px] md:h-[49px] h-[49px] rounded-[50px] w-[100px] md:w-[144px] lg:w-[144px] text-[#CFCFCF] flex justify-center items-center bg-gradient-to-b from-[#5B0F58] to-[#C31091] '>
<p className='Inter Int-400 text-[#CFCFCF]'>search</p>
</div>
</div>
</div>
<Link to='/allcampus' className='flex cursor-pointer gap-2 items-center  '>
        <p className='text-[#fff] text-[12px] underline mt-5 '>Show Me All Campuses</p>
        </Link>
{/* search list */}
<SearchList data={form} searchKey={searchKey}/>
    </div>
    </div>
    <img className='absolute bottom-0 h-[200px] w-full  object-cover lg:hidden md:hidden ' src={smc1} alt="" />
    <img className='lg:absolute  lg:z-[1] lg:bottom-0 lg:w-[100vw]  lg:object-contain hidden lg:block md:block md:object-contain md:absolute md:w-full md:bottom-0' src={image2} alt="" />

  </div>
</div>
<div  className='relative'>
  {!searchKey&&
<>  <Objective/>
<Feature/></>
  }
    
    <Product/>
    <Habesha/>
    <LandFooter/>
</div>
    </section>
 
  )
}
