import React from 'react'

import Registerui from './Registerui';
import logo192 from '../Assets/logo512.png'

import cuate from '../Assets/comingsoon/cuate.png'
import LandFooter from './LandFooter';
export default function ComingSoon(props) {

  return (

    <div className='flex flex-col items-center overflow-hidden'>
   <div className='max-w-[1440px]  '>
<div className=' mb-52 md:mb-24 lg:mb-0 comingBG h-[400px] md:h-[800px] lg:h-[1024px] w-full lg:max-w-[1440px] lg:w-[100vw]'  >
<div className='flex flex-col items-center  '>
  <img className='lg:w-[227px] lg:h-[97px] mt-[50px] md:mt-[127px]  w-[195px] h-[83px]' src={logo192} alt="" />
  <h1 className='Poppins Popp-500 lg:text-[32px] text-[24px] mt-[25px]'>WE ARE COMING SOON!!</h1>
  <p className='Poppins Popp-400 text-[16px] text-center p-4 lg:mx-[335px]'>Sorry the fellowship  page you are looking  for is under construction  but don't worry if you Register here we will let you know as soon as it is available.
</p>
<span className='text-[20px] Poppins Popp-500'>register below</span>
</div>
<div className='flex flex-col items-center'>
<img className=' mt-[40px] w-[600px]' src={cuate} alt="" />
  {/* <img className='h-[170px] w-[200px] mt-[40px]' src={bayck} alt="" /> */}
</div>
  </div>

<Registerui comingsoon={true} caption={props?.caption} key={props?.caption?.Registration_Section_Contents?.campusID} campusID={props?.caption?.Registration_Section_Contents?.campusID} fellowname={props?.fellowname} />
  <div className=''>

  <LandFooter/>
  </div>
    </div>
    </div>
  )
}
