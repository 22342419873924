import React from 'react'

export default function FeatureCard(props) {
  return (
    <div className={` ${props.mt} hover:  hover:from-[#da23ff]  hover:to-[#0c34d3] rounded-[40px] flex flex-col items-center justify-center w-[158px] md:w-[308px] md:h-[379px]  lg:w-[20vw] h-[260px] lg:h-[379px] border-1 border-[#F2F2F2] bg-[#FFF]  `} style={{
        boxShadow:' 0px 10px 50px 0px rgba(0, 0, 0, 0.05)'
    }} >
        <div className={`flex flex-col justify-center items-center bg-[#F1F7FF] w-[100px] h-[100px] md:w-[141px] md:h-[141px] lg:w-[121px] lg:h-[121px] rounded-[20px] ${props.color}  `}>
      {props.icon}
        </div>
        <div className='mt-[31px] flex flex-col gap-[15px] justify-center items-center'>
            <p className='lg:w-[252px] md:w-[252px] w-[220px] md:text-[24px] text-[20px] lg:text-[24px] Popp-500 Poppins text-center'>{props.title}</p>
            <p className='lg:px-2 md:w-[252px] hidden lg:block md:block w-[150px] md:text-[16px] text-center text-[11px] lg:text-[16px] Poppins Popp-400 text-[#757575]'> {props.deatile}</p>
        </div>
    </div>
  )
}
