import React from 'react'

export default function Button(props) {
  return (
  <a  href="#register">  <button style={{
    boxShadow:props.bxshadow
  }} className={props.classname}>{props.button}{props.children}</button></a>
     
  )
}
