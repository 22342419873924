import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
export default function Carroussel(props) {
  const [table,setTable]=useState([]);
  const [offsetRadius, setOffsetRadius] = useState(3);
  const [showArrows, setShowArrows] = useState(true);
  const [goToSlide, setGoToSlide] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cards,setCards] = useState(table);
useEffect(()=>{
  const tables = props?.cards?.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });
setCards(tables)
},[props?.cards, table])
  useEffect(() => {
    setOffsetRadius(props?.offset);
    setShowArrows(props?.showArrows);
  }, [props?.offset, props?.showArrows]);

  const renderDots = () => {
    return <div className="flex items-center  lg:flex lg:justify-center md:flex md:justify-center md:items-center lg:items-center w-full ">
{0==currentSlide?'':
<svg 
onClick={() => {
  setCurrentSlide(currentSlide - 1);
  setGoToSlide(currentSlide - 1);
}}
 className="   md:cursor-pointer  lg:cursor-pointer lg:mr-4 "
width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.1213 10.5001H21V13.5001H8.1213L13.0606 18.4395L10.9393 20.5608L2.37866 12.0001L10.9393 3.43945L13.0606 5.56077L8.1213 10.5001Z" fill="#6B1768"/>
</svg> 
  }
       {cards?.map((_, index) => (
      <span
        key={index}
        style={{
          display: "inline-block",
          width: "8px",
          height: "8px",
          borderRadius: "4px",
          backgroundColor: currentSlide === index ? "#6B1768" : "gray",
          margin: "0 4px",
          cursor: "pointer",
        }}
        onClick={() => {
          setGoToSlide(index);
          setCurrentSlide(index);
        }}
      ></span>
    ))}
    {cards?.length==currentSlide+1?'':
     <svg 
       onClick={() => {
        setCurrentSlide(currentSlide + 1);
        setGoToSlide(currentSlide + 1);
      }}
        className="   md:cursor-pointer  lg:cursor-pointer lg:ml-4 "
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.478 13.5568H2.92432V10.4428H15.478L10.6633 5.31571L12.7311 3.11377L21.0757 11.9998L12.7311 20.8858L10.6633 18.6838L15.478 13.5568Z" fill="#6B1768"/>
</svg> 
  }
    </div>
  };

  return (
    <div className="flex flex-col items-center justify-center">

    <div 
      style={{ width: props?.width, height: props?.height, margin: props?.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config?.gentle}
        afterSlide={setCurrentSlide} // Update the current slide after each slide transition
      />
    </div>
      <div className="text-center mt-[12px]   ">{renderDots()}</div>
    </div>
  );
}