import React, { useRef } from 'react'
import Css from '../Component/Css';
import wel from '../Assets/wel.png'
import {  useInView } from "framer-motion";
import { BASE_URL } from '../Page/Url';
function Welcom(props) {
    const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});
  return (
   <div>
    <div ref={ref} className={`${isView?'   overflow-hidden ':'overflow-hidden'}  ${Css.CSSTailwind.message.message_section}`}>
       <img className={Css.CSSTailwind.message.Image_BackGround} src={wel} alt="welcom image" />
    <div  data-aos="zoom-in" className={`  ${Css.CSSTailwind.message.message_Contenor}`}>
    {/* message contenor */}
   <div className={Css.CSSTailwind.message.message_box}>
    {/* left */}
   <div className={Css.CSSTailwind.message.left}>
    {/* icon */}
<div  data-aos="zoom-in" className={Css.CSSTailwind.message.Icon}>
<p className={Css.CSSTailwind.message.Icon_Name}>“</p>
    </div>
{/* message */}
<div data-aos="fade-left" className={`${isView?'translation-all duration-500  translate-x-0 ':'-translate-x-24 '} ${Css.CSSTailwind.message.message_pack}`}>
    <p className={Css.CSSTailwind.message.message_title}>{props.props?.title}</p>
    <p className={Css.CSSTailwind.message.message}>{props.props?.message}</p>
    </div>
    {/* name_section */}
    <div className='flex justify-end'>
    <div className={`${isView?'translation-all duration-500  translate-x-0':'-translate-x-24'} ${Css.CSSTailwind.message.name_section}`}>
        <p className={Css.CSSTailwind.message.name}>{props.props?.author}</p>
        <p className={Css.CSSTailwind.message.posetion}> {props.props?.authorPosition}</p>
        </div>

    </div>
        </div>
       {/* right */}
    <div className={Css.CSSTailwind.message.right}>
       <img data-aos="fade-left" className={` ${isView?'translation-all duration-500  translate-x-0 hover:-translate-y-6':'-translate-x-full '} ${Css.CSSTailwind.message.Image}`} src={`${BASE_URL}/${props.props?.image}`} alt="" /> 
    </div>
        </div>
    </div>
       </div>
    </div>
  )
}

export default Welcom;
