import React, { useState } from 'react'
import Fellowship from './Fellowship';
import Ourcity from './Ourcity';
import Hero from './Hero';
import Programs from './Programs';
import Registerui from './Registerui';
import Contactui from './Contactui';
import Gallery from './Gallery';
import DataPackage from '../Component/DataPackage';
import Welcom from './Welcom';
import AboutCampus from './AboutCampus';
import FellowTeams from './FellowTeams';
import { useEffect } from 'react';
import {  useParams } from "react-router-dom";
import axios from 'axios';
import Footer from './Footer';
import {BASE_URL} from '../Page/Url'
export default  function   CampusDemo () {
  const [welcome,setWelcom]=useState(null)
  const [Notification,setNotify]=useState(null)

  const {campusID} = useParams();
 

useEffect(()=>{
  if(campusID!=null&&campusID!='undefined'){ 
    axios.post(`${BASE_URL}/api/view-campus-demo?campusID=${campusID}`)
    .then(res=>{
     const data = res.data;
  
     if(res.data&&res.data?.status==='200'){
       setWelcom(data)
     }else {
        setNotify(res.data.message)
     }
   
    }).catch(error=>{
     
   
     setNotify(error)
    })
   }
    

 
},[])



  return (
    <div className=''>
      {!welcome&&welcome!=null&&welcome!='undefined'?
      <> 
      
          <Hero props={welcome.Welcome_Section_Contents}/>
       <Welcom props={welcome.Intro_Section_Contents}/>
        
  <Ourcity Fewclouds={DataPackage.city.Fewclouds} Clearsky={DataPackage.city.Clearsky} props={welcome.City_Section_Contents}/>
  <AboutCampus props={welcome.About_Section_Contents}/>
 
    <Fellowship props={welcome.Fellowship_Section_Contents}/>
   
    <Programs props={welcome.Service_Section_Contents}/>
    <FellowTeams content={DataPackage.Teams} props={welcome.Team_Section_Contents}/>
  
  <Contactui props={welcome.Leader_Section_Contents}/>
  {welcome.Registration_Form_Visibility!=true?'':
  <Registerui props={DataPackage.registerui} campusID={welcome.Welcome_Section_Contents.campusID}  campus={welcome.Welcome_Section_Contents.title} />
  
  
  }
   
  <Gallery props={welcome.Gallery_Section_Contents}/>
  <Footer lat={parseFloat(welcome?.Footer_Section_Contents?.mapLatitude)} long={parseFloat(welcome?.Footer_Section_Contents?.mapLongitude)} city={welcome.City_Section_Contents} props={welcome.Footer_Section_Contents} social={welcome.Social_Medias}/>
  </>:
  <div>
    <p>{Notification}</p>
    </div>
  
}
    </div>
  )
}
