import React from 'react'
import image6 from '../Assets/Landing/image6.png'
import elips1 from '../Assets/Landing/elips1.png'
import elips2 from '../Assets/Landing/elips2.png'
import image5 from '../Assets/Landing/image5.png'
import elips3 from '../Assets/Landing/elips3.png'
import elips4 from '../Assets/Landing/elips4.png'
import image7 from '../Assets/Landing/image7.png'
import image8 from '../Assets/Landing/image8.png'
import ET from '../Assets/Landing/ET.png'
import image3 from '../Assets/Landing/land1.png'
import image4 from '../Assets/Landing/land2.png'
import Campus from '../Assets/Landing/campus.png'
import campusmd from '../Assets/Landing/campusmd.png'
import {  useInView } from "framer-motion";
import { useRef } from 'react'

export default function Objective() {
        const ref =useRef(null);
        const isView =useInView(ref,{margin:'-10% 0px -10% 0px'});
        const refs =useRef(null);
        const isViews =useInView(refs,{margin:'-10% 0px -10% 0px'});
  return (
    <section id="Objective"  className='overflow-hidden'>
        <div  className='lg:mt-[100px] md:mt-[100px] relative '>

        <div className='lg:h-full w-full h-[1147px] md:h-auto'>
          <img className='w-full h-[1137px] md:h-[90vh] lg:h-[80vw] ' src={image6} alt="" />
        </div>
        <div className='lg:absolute lg:top-0 hidden md:top-0 md:absolute lg:block md:block '>
<img className={` transform-all duration-700 mt-[30%] w-[4vw]`} src={elips1} alt="" />
<img className={` transform-all duration-1000 md:w-[240px] lg:w-[25vw] `} src={elips2} alt="" />
<img className='mt-[-200px] lg:h-auto md:mt-[-130px] md:h-[360px] md:object-cover lg:w-[15vw] md:ml-[2vw] md:w-[20vw]' src={image5} alt="" />
        </div>

        <div className='absolute top-0 right-0 lg:mr-[-50px] md:mr-[-80px] overflow-hidden '>
        <img src={ET} alt="" className='lg:mt-[100px] mt-[50px] md:mt-[70px] lg:w-[28vw] md:w-auto w-[200px]  md:h-auto lg:h-auto h-[150px]  ' />
<img src={elips3} alt="" className={`transform-all duration-1000 lg:ml-[110px] lg:mt-[164px] lg:w-[25vw] md:ml-[90px] md:mt-[334px] hidden lg:block md:block md:w-[25vw] `} />
<img className='lg:mt-[-80px] lg:h-auto lg:w-[28vw] hidden  lg:block md:hidden' src={image7} alt="" />
        </div>
        

    <div className='absolute top-0 '>
        {/* top content */}
        <div
       
        className='flex  lg:w-[100%] md:gap-[50px] md:w-[100vw]'>
<img className='lg:mt-[-80px] hidden lg:block md:block md:w-[40vw] lg:w-[38vw] lg:h-auto  md:h-auto md:mt-[-80px] md:object-contain' src={image3} alt="" />
<div ref={ref} className='lg:mt-[164px] mt-[140px] text-white md:mr-[2vw] lg:w-[60%]'>
    <p className={`${isView?' scale-100 ':' scale-150 '} transform-all duration-1000 text-[36px] Poppins Popp-900 font-bold text-center lg:text-start md:text-start lg:w-[20vw] `}>Welcome</p>
    <p 
     
    className={` ${isView?' scale-100 ':' scale-150 '} transform-all duration-700 lg:mr-[18%] text-[14px] md:text-[14px] md:text-left mx-[7%] md:mx-0 lg:mx-0 lg:text-[16px] Poppins Popp-500 leading-[183.9%] tracking-[0.32px]`}>Welcome to myfellow, an online platform designed to help you connect with Christian fellowship on campus. We understand that being a new student can be overwhelming, especially when it comes to finding a community of believers. That's why we are here to assist you every step of the way. Our platform provides information on local fellowships and helps you get connected with like-minded individuals. We also offer assistance to fellowship leaders in registering new students. Let us help you find the perfect community to grow in your faith and make lasting connections. We are excited to have you here and look forward to embarking on this journey with you!
          </p>
</div>
        </div>
        {/* bottom content */}
        <div ref={refs} className='flex lg:justify-end lg:ml-11'>       
<div className=' mt-[20px] lg:mt-[80px] md:mt-[80px] lg:w-[55%] text-white lg:ml-[15.8%] md:ml-[5%] lg:mr-[-55px] mx-[7%] lg:mx-0 md:mx-0 '>
    <p className={` ${isViews?' scale-100 ':' scale-150 '} transform-all duration-1000 text-[36px] Poppins Popp-900 font-bold text-center lg:text-start md:text-start `}>Objective</p>
    <p className={`${isViews?' scale-100 ':' scale-150 '} transform-all duration-700 lg:w-auto md:w-[45vw] text-[14px] lg:text-[16px] Poppins Popp-400 leading-[183.9%] tracking-[0.32px] `}>
    We are here to help you find Christian fellowship on campus and connect with like-minded individuals. Our ultimate goal is to create a link between you and your fellowship leaders, making it easier for you to experience spiritual growth and get involved in your community. We provide information to incoming students and assist fellowship leaders in registering new students. Let us help you find the community you're looking for and embark on a journey of personal and spiritual development!
        </p>
</div>
<div className=' mt-[-80px] lg:mt-[-40px] md:mt-[120px] mr-[75px]  relative lg:w-[50%]  lg:h-auto hidden lg:block md:block '>

<img className='lg:h-[100%] lg:w-[100%] lg:items-start   hidden lg:block md:block md:w-[40vw] md:mr-[5vw] md:h-auto ' src={image4} alt="" />
<img className={` transform-all duration-1000 lg:absolute lg:top-[-5px] lg:left-[150px] hidden lg:block  md:block md:absolute md:top-[-15px] md:left-[12vw] lg:w-[6vw]`} src={elips4} alt="" />
</div>
        </div>
    </div>
    <img className='absolute bottom-5 lg:hidden md:block hidden md:object-cover md:bottom-[-40px]  md:w-full object-contain ' src={campusmd} alt="" />
    <img className='absolute bottom-5 lg:hidden md:hidden  ' src={Campus} alt="" />
    <div className='absolute bottom-0 h-[15vh] w-full lg:h-[20vw] md:h-[10vh] lg:mb-[-80px] md:w-full lg:w-full'>
            <img className='w-full h-full  md:w-full md:h-full md:object-cover' src={image8} alt="" />
        </div>
        </div>
    </section>
  )
}
