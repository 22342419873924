import React, { useEffect, useState } from 'react'

import ship from '../Assets/ship.png'
import { useRef } from 'react'
import { useInView } from 'framer-motion'
import {BASE_URL} from '../Page/Url'
export default function Ourcity(props) {
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});
  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);
     setDeviceType(isMobile ? "mobile" : "desktop");
  }, [deviceType]);
  return (
    <div>
    <div ref={ref} className='md:pb-[180px] lg:pb-[180px] xl:pb-[80px] overflow-hidden '>
        <div className='text-center mb-[39px]'>
            <p  className={`${isView?'translation-all duration-500 scale-500':'scale-0'} text-[24px] Popp-600 md:text-[48px] lg:text-[48px] Poppins md:leading-[48px] tracking-[-0.72px] lg:leading-[72px] lg:tracking-[-0.03em]  `}>{props?.props?.title}</p>
            <p  className={`${isView?'translation-all duration-500 scale-500':'scale-0'} text-[#6B1768] text-[12px] leading-[28px] Poppins Popp-400  md:text-[14px] lg:text-[20px] Poppins font-[400] lg:leading-[28px] tracking-[-0.2px] text-[#6B1768] `}>{props?.props?.subtitle}</p>
            </div>                        
            <div className='relative flex flex-col-reverse  md:flex-row lg:flex-row '>
                <div className='mt-[-70px] md:mt-5 lg:mt-0 mr-[-90px] md:mr-0 lg:mr-0 flex justify-end lg:justify-start md:justify-start items-center '>
                <img data-aos="zoom-in-right" className= 'ml-[-90px] mt-[-70px] md:ml-0 md:mt-0 w-[200px] lg:mt-0 lg:w-auto lg:h-auto md:w-[509px] md:h-auto object-fill '  src={ship} alt="" />
              
                    </div>
                    <div style={{
                        boxShadow:`${deviceType==='mobile'?'':'0px 4px 70px rgba(0, 0, 0, 0.09)' }`
                    }} className={`${isView?'translation-all duration-500  translate-x-0 flex':'-translate-x-96 flex'} 'flex lg:h-auto lg:w-auto flex-col lg:items-center lg:justify-center lg:flex-row-reverse md:flex-row-reverse md:justify-center md:items-center text-justify justify-center  items-center lg:bg-white md:bg-white mt-[-20px] lg:absolute md:absolute top-0 lg:right-8 lg:left-[209px] lg:top-[73px] md:mx-5 md:right-[0px]  md:left-[0px] rounded-[20px] '`}>
                       <p data-aos="fade-up"
     data-aos-anchor-placement="top-center" className='mx-[5vw] md:mx-4 lg:mx-0 lg:ml-[33px] Popp-400 lg:mt-[78px] md:mt-[32px] md:mb-[32px] lg:mb-[50px] text-[#52525B]  lg:mr-[31px] lg:text-[18px] md:text-[16px] Poppins leading-[20px] taracking-[-0.28px] lg:tracking-[-0.02em] font-[400]  lg:leading-[30px]'>{props.props?.description}</p>
                       <img data-aos="fade-down-left" className='w-[200px] h-[200px] mb-8 mt-20 md:mt-0 lg:mt-[70px]  md:ml-[24px] lg:ml-[30px]  lg:w-[295px] lg:h-[286px] md:w-[200px] md:h-[200px] rounded-full' src={`${BASE_URL}/${props?.props?.bg_Image}`} alt="" />

                        </div>
                </div>      
        </div>
    </div>
  )
}
