import React, { useState } from 'react'
import {BASE_URL, ReactGAs} from '../Page/Url'

import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'
ReactGA.initialize("G-VP1SF5LD7L");
export default function SearchList(props) {
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [searchQuery, setSearchQuery] = useState('');
    const filteredData =  props?.data?.filter((item) => {
        if (
          item?.universityName?.toLowerCase().includes(props.searchKey?.toLowerCase()) ||
          item?.campusName?.toLowerCase().includes(props.searchKey?.toLowerCase()) ||
          item?.cityName?.toLowerCase().includes(props.searchKey?.toLowerCase()) ||
          item?.fellowshipAcronym?.toLowerCase().includes(props.searchKey?.toLowerCase()) ||
          item?.fellowshipName?.toLowerCase().includes(props.searchKey?.toLowerCase())  
        ) {
          return true;
        }
      
        return false;
      });

      const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + rowsPerPage;

      const currentItems = filteredData?.length>=0&& filteredData?.slice(itemOffset, endOffset);
     
  
    const [count,setCount]=useState(2);
    function onAction(url,fellowshipName,image){
     
      ReactGA?.event({
        category: 'visitors of fellowship page',
        action: "fellowship-page",
        label: fellowshipName, // optional
        value: 1, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: image, // optional, beacon/xhr/image
      });
      window?.open(url);
    }
  return (
   
    <div className='z-[50]'>
         {props.searchKey&&currentItems?.length>0&&
        
        <div
style={{
  boxShadow:'0px 4px 24px -1px rgba(0, 0, 0, 0.25)'
}}
className=' Int-400  flex flex-col bg-gradient-to-tb backdrop-blur-[20px] from-[#876161] to-[#fff] justify-center  items-center rounded-[20px]  w-full     text-white'>
<div className='flex flex-col gap-4 lg:grid lg:grid-cols-3 md:grid md:grid-cols-3 justify-center  lg:mx-0 xl:mx-[25px]   md:mx-[25px] '>

{currentItems&&currentItems?.map((data,index)=>(
    <div key={index}>
    {index<=count?
<a target='_blank' onClick={()=>onAction(data.url,data.fellowshipName,`${BASE_URL/data?.image}`)} key={index} className='flex bg-black hover:bg-[#5b325a90]  lg:w-[354px] xl:w-[354px] md:w-[218px]  w-[300px]  h-auto rounded-[20px] mt-4 md:mt-[31px] lg:mt-[31px]    '>
    <div className='text-[#000]   md:items-start p-2 md:p-4 lg:p-4 flex lg:flex-col md:flex-col flex-row gap-4 lg:gap-0 md:gap-0 '>
        <img className='lg:w-[354px] md:w-[218px] md:h-[145px] w-[72px] h-[72px]  lg:h-[236px] lg:rounded-[12px] md:rounded-[12px] rounded-[8px] md:mb-[15px] lg:mb-[15px] bg-cover' src={`${BASE_URL}/${data?.image}`} alt="" />
        <div className='flex  flex-col'>
        <p className='  Searchfname px-2  text-[12px] lg:text-[16px] md:text-[16px] '>{data.campusName}</p>
        <p className=' px-2 text-[14px] md:text-[20px] lg:text-[24px] xl:text-[24px] Poppins Popp-500'>{data.universityName}</p>
        <p className='Searchfnames px-2 text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] '>{data.fellowshipName}</p>
        

        </div>
    </div>
</a>:
''
    }
</div>
))}
</div>
<div className='w-full'>   
    {currentItems.length>3?
    <>
    <div className='w-full p-[1px] bg-[#F2F4F8]'></div>
    <div className='mx-4 p-2 text-gray-500 flex gap-4 justify-between items-center'>
        <p className='text-[#999FAA] text-[10px]'>{currentItems?.length} results</p>
        
        <div className='flex gap-8 '>
        <Link to='/allcampus' className='flex cursor-pointer gap-2 items-center  '>
            <p className='text-[#000] text-[10px] underline '>Show Me All Campuses</p>
            </Link>
            {currentItems?.length===count?'':
            <div onClick={()=>setCount(count+3)} className='flex cursor-pointer gap-2 items-center  '>
            <p className='text-[#000] text-[10px] underline '>Show me more results</p>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.51788 0.238643L13.7588 5.42386C14.0804 5.74205 14.0804 6.25794 13.7588 6.57614L8.51788 11.7614C8.19628 12.0795 7.67485 12.0795 7.35324 11.7614C7.03163 11.4432 7.03163 10.9273 7.35324 10.6091L11.1883 6.81478H0V5.18522H11.1883L7.35324 1.39091C7.03163 1.07272 7.03163 0.556834 7.35324 0.238643C7.67485 -0.0795478 8.19628 -0.0795478 8.51788 0.238643Z" fill="black"/>
</svg>

            </div>
            }
     

        </div>
    </div>
    </>
    :<Link to='/allcampus' className='flex cursor-pointer gap-2 p-2 items-center justify-center w-full'>
    <p className='text-[#000] text-[10px] underline'>Show Me All Campuses</p>
   

    </Link>
    }
</div>
</div>
        
    }
        </div>
  )
}
