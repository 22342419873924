import Nav from "./Component/Nav";
import Campus from "./Page/Campus";
import  Login  from "./Page/Login";
import Ourcity from "./Page/Ourcity";
import { BrowserRouter,HashRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import Footer from "./Page/Footer";
import DataPackage from "./Component/DataPackage";
import { useEffect } from "react";
import axios from 'axios';
import CampusDemo from "./Page/CampusDemo";
import Landing from "./Page/Landing";
import Allcampus from "./Allcampus/Allcampus";
import { Empty } from "antd";
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/:id" element={<Campus />} />
      
      <Route path="/allcampus" element={<Allcampus />} />
      <Route path="*" element={<Empty/>}/>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
