import React, {  useEffect, useState } from "react";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {  message} from 'antd';
import menu from '../Assets/menu.png'
import { useRef } from "react";
import { useInView } from "framer-motion";
import { BASE_URL, ReactGAs,CAPCH_KEY } from "../Page/Url";
import ReactGA from 'react-ga4'
ReactGA.initialize("G-J5H5084J8F");
function Registerui(props) {

  const ref =useRef(null);
const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});

  const [messageApi, contextHolder] = message.useMessage();
  const [wait,setWait]=useState(false);
  const [errorInput,setErrorInput]=useState(null);
  const recaptchaRef = React.useRef();

const inputStyle =
"border-b border-[#868686] mt-1 block w-full textfont md:px-0 py-2 bg-white   text-sm  placeholder-slate-400  focus:outline-none  focus:border-b-[#6B1768] focus:ring-b-1 focus:ring-b-[#6B1768] disabled:bg-[#868686] disabled:text-slate-500 disabled:border-[#868686] disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 Poppins Popp-400 ";
const background=props?.props?.background;
const lableStyle='mb-[-20px]   z-10 text-[#868686] text-[14px] Poppins Popp-400 leading-[35px] tracking-[0.08em]'
const [selectedGender, setSelectedGender] = useState('');
const [selectedHost, setSelectedHost] = useState('');

const [form,setForm]=useState({
  firstName:'',
  lastName:'',
  city:'',
  language:'',
 
  phone:'',
  
  church:'',
  campusID:props?.campusID,
  arrivalDate:'',
  comingsoon:props?.comingsoon
});

function HandleChange(e){
  e.preventDefault();
  setForm({...form,[e.target.name]:e.target.value})
}
function handleGenderChange  (e) {
  setSelectedGender(e.target.value);
};
function handleHostChange(e){
  setSelectedHost(e.target.value);
}
const [userIp, setUserIp] = useState('');

  useEffect(() => {
    // Function to fetch user IP from a third-party service
    const fetchUserIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIp(response.data.ip); // Set the user's IP in state
       // console.log(response.data.ip)
      } catch (error) {
        //console.error('Error fetching IP:', error);
      }
    };

    fetchUserIp(); // Fetch user IP when the component mounts
  }, []);

const [captchaIsDone,setCaptchaDone]=useState(null);
function resetCaptcha () {
  if (recaptchaRef.current) {
    recaptchaRef?.current?.reset();
    setCaptchaDone(false);
     // Reset the captcha status
  }
}


  function handleSend(){
   
  setWait(true)
  if(form.firstName!=null&&form.firstName!=''&&form.lastName!=null &&form.lastName!=''&&form.city!=null&&form.city!=''&&form.language!=null&form.language!=''
  &&form.phone!=null&&form.phone!=''&&selectedHost!=''&&selectedHost!=null&&form.church!=null&&form.church!=''&&form.arrivalDate!=null&&form.arrivalDate!=''
  &&selectedGender!=null&selectedGender!=''
  ){
   if(captchaIsDone){
  axios.post(`${BASE_URL}/api/register?`,{
    firstName:form.firstName,
    lastName:form.lastName,
    city:form.city,
    language:form.language,
    sex:selectedGender,
    phone:form.phone,
    isHostAvailable:selectedHost,
    church:form.church,
    campusID:form.campusID,
    arrivalDate:form.arrivalDate,
    reCAPTCHA:captchaIsDone,
    IPwun:userIp,
    registerTemporaryStudent:form?.comingsoon||false
  })
  .then(res=>{
 if(res.data.status===200){
 resetCaptcha();
  ReactGA?.event({
    category: 'student registered page ',
    action: "student-register",
    label: props?.fellowname?.fellowshipName, // optional
    value: 1, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: 'xhr', // optional, beacon/xhr/image
  });
  openMessage(res?.data?.message,'success',1)
  setForm({
    firstName:'',
    lastName:'',
    city:'',
    language:'',
    phone:'',
    church:'',
    campusID:props?.campusID,
    arrivalDate:''
  })

  setWait(false);
  setSelectedGender('')
  setSelectedHost('')
}else{
  setWait(false);
 resetCaptcha();
  openMessage(res?.data?.message,'error',3);
}
    }).catch((error)=>{
      setWait(false);
     resetCaptcha()
      openMessage(error?.data?.message,'error',3);
    })
  }else{
    setWait(false);
    openMessage('Select the captcha! Slow internet may hide it','error',2)
    
  }
  }else{
   resetCaptcha()
    openMessage('All field requerd!','error',2)
    setWait(false);
  }
}
const openMessage = (content,types,keys) => {
  setErrorInput(content)
  messageApi?.open({
    key:keys,
    type: 'loading',
    content: 'Loading...',
  });
 //  setTimeout(() => {
    messageApi?.open({
      key:keys,
      type: types,
      content: content,
      duration: 2,
    });
  
  // }, 1000);
};
const [type, setType] = useState('text');
const onFocus = () => {
  setType('date');
};

// useEffect(() => {
//   const script = document.createElement('script');
//   script.src = 'https://www.google.com/recaptcha/api.js';
//   document.body.appendChild(script);

//   return () => {
//     // Clean up: Remove the script when the component unmounts
//     document.body.removeChild(script);
//   };
// }, []);

  return (
    <section ref={ref} className=" overflow-hidden relative h-[850px] md:h-[885px] lg:h-auto " id='register '>
  {contextHolder}
   <div id="register" className='grid md:grid-cols-3 relative '>
    <div className="hidden md:block md:row-span-3  md:h-[885px] lg:h-[900px]">
      <img className="object-cover  h-full w-full " src={menu} alt="" />

    </div>
    <div className="col-span-2 row-span-3 relative flex ">
      <div className="absolute top-0 w-full ">

    <div className="relative hidden md:block lg:block content lg:w-full lg:relative md:w-full md:relative ">
<p className={` ${isView?'translation-all duration-500 scale-500':'scale-0'} "transform absolute md:top-[280px] lg:top-[320px] ml-[-318px] lg:ml-[-400px]  rotate-[-90deg] writing-vertical text-upright text-white md:text-[160px] lg:text-[200px] Popp-600 leading-[300px] Poppins uppercase " `}>{props?.caption?.Registration_Section_Contents.join_Us_Placeholder}</p>
<p className={` ${isView?'translation-all duration-700 scale-500':'scale-0'} "transform absolute md:top-[280px] lg:top-[320px] ml-[-318px]  lg:ml-[-400px]  rotate-[-90deg] writing-vertical text-upright text-black md:text-[160px] lg:text-[200px] Popp-600 leading-[300px] Poppins uppercase "`} >{props?.caption?.Registration_Section_Contents.join_Us_Placeholder}</p>
 
   </div>

<div className={`   ${isView?' duration-1000 translate-x-0 ':'-translate-y-full '} "  w-full text-black "`}>
  <div className="text-center">
    <p className="  md:mt-[21px] md:text-[48px] lg:text-[52px] Popp-600 Poppins md:leading-[48px] leading-[62px] lg:leading-[62px] tracking-[-2px] text-[24px] Popp-600 ">{props?.caption?.Registration_Section_Contents?.title}</p>
    <p className=" lg:text-[18px]  md:leading-[50px] lg:leading-[62px] Poppins tarcking-[-1px] text-[#6B1768] text-[14px] Popp-500 leading-[20px]">{props?.caption?.Registration_Section_Contents?.description}</p>
  </div>
  <div className=''>
  <div className="  lg:mt-[10px] flex flex-col  justify-center items-center md:items-start md:w-full">
    <div className="w-full lg:w-[70%] md:w-[100%]  md:px-24 md:first:justify-start grid  md:grid-cols-1 lg:gap-4 md:gap-4 grid-cols-1 lg:grid-cols-1 items-center px-[5vw] gap-4 lg:ml-[135px]">
<div className="flex flex-col  gap-2">
{/* <label for='fname ' className={lableStyle}> {props.props.Fname} </label> */}
<input value={form.firstName} placeholder={props?.caption?.Registration_Section_Contents?.firstNameCaption} name='firstName' className={inputStyle} type="text" id="fname" onChange={(e)=>HandleChange(e)} />

</div>
<div className="flex flex-col gap-2">
{/* <label for='lname' className={lableStyle} > {props.props.Lname} </label> */}
<input value={form.lastName} placeholder={props?.caption?.Registration_Section_Contents?.lastNameCaption} name='lastName' className={inputStyle} type="text" id="lname" onChange={(e)=>HandleChange(e)} />
</div>
<div className="flex flex-col gap-2">
{/* <label for='ycity' className={lableStyle} > {props.props.Ycity}</label> */}
<input value={form.city} placeholder={props?.caption?.Registration_Section_Contents?.cityCaption} name="city" className={inputStyle} type="text" id="ycity" onChange={(e)=>HandleChange(e)} />
</div>
<div className="flex flex-col gap-2">

{/* <label for='Lang' className={lableStyle}> {props.props.Lang} </label> */}
<input value={form.language} placeholder={props?.caption?.Registration_Section_Contents?.languageCaption} name="language" className={inputStyle} type="text" id="Lang" onChange={(e)=>HandleChange(e)} />

</div>
<div className="flex flex-col gap-2">
{/* <label for='Pnumber' className={lableStyle} > {props.props.Pnumber} </label> */}
<input value={form.phone} placeholder={props?.caption?.Registration_Section_Contents?.phoneNumberCaption} name="phone" className={`phone-number-input ${inputStyle}`} type="number" id="Pnumber" onChange={(e)=>HandleChange(e)} />


</div>
<div className="flex flex-col gap-2">

{/* <label for='church' className={lableStyle} > {props.props.church} </label> */}
<input value={form.church} placeholder={props?.caption?.Registration_Section_Contents?.churchCaption} name="church" className={inputStyle} type="text"  id="church" onChange={(e)=>HandleChange(e)} />
</div>
<div className="flex flex-col gap-2 items-center justify-center relative">
<input  value={form.arrivalDate}  placeholder={props?.caption?.Registration_Section_Contents?.arrivalDateCaption} onFocus={onFocus}  name="arrivalDate" className={inputStyle} type={type}  id="arrivalDate" onChange={(e)=>HandleChange(e)} />
<svg className="absolute  right-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M19.5 4H16.5V2.5C16.5 2.36739 16.4473 2.24021 16.3536 2.14645C16.2598 2.05268 16.1326 2 16 2C15.8674 2 15.7402 2.05268 15.6464 2.14645C15.5527 2.24021 15.5 2.36739 15.5 2.5V4H8.5V2.5C8.5 2.36739 8.44732 2.24021 8.35355 2.14645C8.25979 2.05268 8.13261 2 8 2C7.86739 2 7.74021 2.05268 7.64645 2.14645C7.55268 2.24021 7.5 2.36739 7.5 2.5V4H4.5C3.8372 4.00079 3.20178 4.26444 2.73311 4.73311C2.26444 5.20178 2.00079 5.8372 2 6.5V19.5C2.00079 20.1628 2.26444 20.7982 2.73311 21.2669C3.20178 21.7356 3.8372 21.9992 4.5 22H19.5C20.1629 21.9995 20.7985 21.7359 21.2672 21.2672C21.7359 20.7985 21.9995 20.1629 22 19.5V6.5C21.9995 5.83712 21.7359 5.20154 21.2672 4.73282C20.7985 4.26409 20.1629 4.00053 19.5 4ZM21 19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V11H21V19.5ZM21 10H3V6.5C3 5.672 3.67 5 4.5 5H7.5V6.5C7.5 6.63261 7.55268 6.75979 7.64645 6.85355C7.74021 6.94732 7.86739 7 8 7C8.13261 7 8.25979 6.94732 8.35355 6.85355C8.44732 6.75979 8.5 6.63261 8.5 6.5V5H15.5V6.5C15.5 6.63261 15.5527 6.75979 15.6464 6.85355C15.7402 6.94732 15.8674 7 16 7C16.1326 7 16.2598 6.94732 16.3536 6.85355C16.4473 6.75979 16.5 6.63261 16.5 6.5V5H19.5C19.8978 5 20.2794 5.15804 20.5607 5.43934C20.842 5.72064 21 6.10218 21 6.5V10Z" fill="#868686"/>
</svg>
</div>
<div className="flex flex-col gap-2 ">
<label className="flex gap-[108px]"> 
<div className="flex flex-col gap-4 space-y-3">
<div className="flex flex-col md:flex-col lg:flex-col lg:gap-0  gap-4 justify-center ">
<label className={`${lableStyle} mb-[4px] `} >{props?.caption?.Registration_Section_Contents?.sexCaption} </label>
      <div className="flex  justify-start items-center "> 
        <input
        
          type="radio"
          value="male"
          checked={selectedGender === 'male'}
          onChange={(e)=>handleGenderChange(e)}
          className="mr-2"
        />
         <label className="block text-[#868686] tracking-[0.08em] leading-[35px] Poppins text-[13px] font-[400]">{props?.caption?.Registration_Section_Contents?.maleCaption}</label>
      </div>
      <div className="flex  justify-center items-center">
     
        <input
        id="female"
          type="radio"
          value="female"
          checked={selectedGender === 'female'}
          onChange={(e)=>handleGenderChange(e)}
          className="mr-2"
        />
           <label  className="block text-[#868686] tracking-[0.08em] leading-[35px] Poppins text-[13px] font-[400]">{props?.caption?.Registration_Section_Contents.femaleCaption}</label>
      </div>
     
    </div>
</div>

<div className="flex flex-row gap-4 space-y-3 md:w-full">
<div className="flex flex-col md:flex-col lg:flex-col lg:gap-0 gap-4 md:w-full justify-center ">
<label  className={`${lableStyle} mb-[4px]  `} > {props?.caption?.Registration_Section_Contents?.isHostAvailableCaption} </label>
      <div className="flex  justify-start items-center">
        <input
          type="radio"
          value="1"
          checked={selectedHost === '1'}
          onChange={(e)=>handleHostChange(e)}
          className="mr-2   "
        />
         <label className="block text-[#868686] tracking-[0.08em] leading-[35px] Poppins text-[13px] font-[400]">{props?.caption?.Registration_Section_Contents?.yesCaption}</label>
      </div>
      <div className="flex  justify-start items-center">
     
        <input
        style={{
          color:'#6B1768',
          backgroundColor:'#6B1768',
          borderColor:'#6B1768'
        }}
          type="radio"
          value="0"
          checked={selectedHost === '0'}
          onChange={(e)=>handleHostChange(e)}
          className="mr-2 "
        />
           <label className="block text-[#868686] tracking-[0.08em] leading-[35px] Poppins text-[13px] font-[400]">{props?.caption?.Registration_Section_Contents?.noCaption}</label>
      </div>
     
    </div>

</div>

</label>
</div>


<ReCAPTCHA
ref={recaptchaRef}
    sitekey={CAPCH_KEY}
    onChange={(val)=> setCaptchaDone(val)}
  />

    </div>
    <div className="flex justify-start items-center lg:ml-[235px] md:ml-[100px] ">

    <button style={{
    }} onClick={()=>handleSend()} 
    className=" RegisterButton flex items-center justify-center  text-[14px] h-[29px]  md:h-[50px] md:w-[260px] lg:h-[60px] lg:w-[260px] my-5 h-[35px] w-[150px] py-5 md:py-2  lg:px-0 md:px-0 md:mt-8 lg:mt-[60px] text-white rounded-[80px] md:text-[20px] Poppins Popp-500">
       {wait!=false?<p> 
Processing...</p>
:<>{props?.caption?.Registration_Section_Contents?.buttonName}</>}</button>
       
    </div>
  </div>
  </div>
</div>
      </div>
    </div>
   </div>

    </section>
  );
}

export default Registerui;
