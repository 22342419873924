import React, { useEffect, useRef, useState } from 'react'
import ship from '../Assets/ship.png'
import { useInView } from 'framer-motion';
import {BASE_URL} from '../Page/Url'
function AboutCampus(props) {
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});

  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);
     setDeviceType(isMobile ? "mobile" : "desktop");
   
  }, [deviceType]);
  return (
<div  id="About">
    <div ref={ref}  className='overflow-hidden '>
        <div className='text-center mb-[39px]  md:pb-8 '>
            <p className={`${isView?'translation-all duration-500 scale-500':'scale-0'} 'text-[24px] md:text-[48px] lg:text-[48px] mt-[37px] lg:mt-[80px] Poppins Popp-600 md:leading-[48px] lg:leading-[72px] tracking-[-0.72px] lg:tracking-[-0.03em]  '`}>{props?.props?.title}</p>
            <p className={`${isView?'translation-all duration-500 scale-500':'scale-0'} 'text-[#6B1768] text-[12px] leading-[28px] text-[#6B1768] Poppins  Popp-400 md:text-[14px] lg:text-[20px] Poppins  lg:leading-[28px] tracking-[-0.2px] '`}>{props?.props?.subtitle}</p>
            </div> 
            <div className='relative flex flex-col-reverse   md:flex-col lg:flex-col '>
                <div  className='flex   lg:justify-end md:justify-end items-center lg:mr-0 md:mr-[0px]'>
                    <img data-aos="zoom-in-right" className= 'ml-[-90px] mt-[-70px] md:ml-0 md:mt-0 w-[200px] lg:mt-0 lg:w-auto lg:h-auto md:w-[509px] md:h-auto object-fill '  src={ship} alt="" />
              
                    </div>
                    <div  style={{
                        boxShadow:`${deviceType==='mobile'? '':'0px 4px 70px rgba(0, 0, 0, 0.09)'}`
                    }} className={`${isView?'translation-all duration-500  translate-x-0 flex':'translate-x-96 flex'} 'flex flex-col mx-[2vw] lg:h-auto md:flex-row lg:flex-row text-justify justify-center  lg:w-auto  items-center   lg:bg-[#FFF]  md:bg-white mt-[-20px] lg:absolute md:absolute top-0 lg:right-[159px] lg:left-[58px]  lg:top-[53px] md:left-0 md:right-[10px] lg:rounded-[20px] md:rounded-[20px] '`}>
                       <p data-aos="fade-up"
     data-aos-anchor-placement="top-center" className='text-[14px] Popp-400 leading-[20px]  md:p-[18px] lg:p-0 lg:ml-[52px] lg:mr-[31px] lg:mt-[64px] lg:mb-[64px] lg:text-[18px] text-[#52525B] md:text-[16px] Poppins tracking-[-0.28px] lg:tracking-[-0.02em] mx-[5vw] md:mx-0 lg:mx-0   lg:leading-[30px]'>{props.props?.description}</p>
                       <img data-aos="fade-down-right" className='mr-[24px] mb-8 lg:mb-0 md:mt-0 lg:mt-0 mt-20 w-[200px] h-[200px] lg:w-[295px] lg:h-[286px] md:w-[200px] md:h-[200px] rounded-full' src={`${BASE_URL}/${props.props?.logo}`} alt="" />
                      
                        </div>
                </div>      
        </div>
    </div>
  )
}
export default AboutCampus;

