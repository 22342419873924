import React, { useEffect, useState } from 'react'

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import Carroussel from './Carousel'
import { v4 as uuidv4 } from "uuid";
import ProgramCard from '../Component/ProgramCard'
import { useInView } from 'framer-motion'
import { useRef } from 'react'

function Programs(props) {
  
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-50% 0px -50% 0px'});

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  
  const [deviceType, setDeviceType] = useState("");
  const [itemsPerPage,setItemPerPage]=useState(1)
useEffect(() => {
   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

   setDeviceType(isMobile ? "mobile" : "desktop");
   if(deviceType==="mobile"){
     setItemPerPage(1)
     }else{
      setItemPerPage(2)
     
     }
 }, []);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: itemsPerPage,
      spacing: 8
    },
  
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });
 

  

 
const CARDS = props.props?.map(({name,day,address,time})=>({name,day,address,time}));


function convertTo12HourFormat(time24) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24.split(':').map(Number);

  // Determine whether it's AM or PM
  const period = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // Handle midnight (0:00) as 12:00 AM

  // Create the 12-hour time string
  const newMinutes=minutes<10?`0${minutes}`:minutes
  const time12 = `${hours12}:${newMinutes} `;

  return time12==='6:0'?`${time12}+0 ${period}`:`${time12} ${period}`;
} 

let cards = CARDS?.map((item, index) => {
  return {
    key: uuidv4(),
    content: <ProgramCard data-aos="zoom-out-up" className={`${isView?'translation-all duration-700 scale-500':'scale-0'} 'flex flex-col justify-center rounded-t-[20px]  w-[800px] h-[426px]'`} time={convertTo12HourFormat(item.time)} day={item.day} address={item.address} name={item.name} />,
    ...item
  };
});

  return (
    <div ref={ref} id="Services" className="lg:pb-[100px] md:pb-[100px] mb-8 lg:mb-0 md:mb-0">
      <p className={` ${isView?'translation-all duration-500 scale-500':'scale-0'} ' lg:mt-[50px] md:mt-[50px] text-[24px] Popp-600 text-center md:text-[48px] lg:text-[64px] Poppins md:leading-[48px] lg:leading-[62px] tracking-[-2px]'`}>{props?.caption?.services_Title}</p>
      <p className={` ${isView?'translation-all duration-700 scale-500':'scale-0'} ' md:text-[14px]  text-[12px] leading-[30px] Popp-400 lg:text-[20px] text-center  Poppins text-[#6B1768] md:mt-[15px] lg:mt-[20px]'`}>{props?.caption?.services_Subtitle}</p>
    <Carroussel
      cards={cards}
      height={`${deviceType==='mobile'?'300px':'500px'} `}
      width="30%"
      margin="0 auto"
      offset={3}
      showArrows={false}
    />
  </div>
  )
}

export default Programs
