import Clearsky from "../Assets/Weather/11.png";
import Fewclouds from "../Assets/Weather/1.png";
import dress from "../Assets/Weather/dress.png";
import language from "../Assets/Weather/language.png";

import fb from "../Assets/Social/fb.png";
import tg from "../Assets/Social/tg.png";
import tk from "../Assets/Social/tk.png";
import yt from "../Assets/Social/yt.png";
import ig from "../Assets/Social/ig.png";
import welcome from '../Assets/welcome.png'

const welcom = {
  name: "WELCOME TO ARBAMINCH",
  image: welcome,
  text: "Main Campus Fellowship",
  moto:"My dear brothers and sisters, take note of this:  Everyone should be quick to listen, slow to speak and slow to become angry. James 1:19",
 button:'Register now',
  welcomeSpeach:
    "Welcome to the Arba Minch University Christian Fellowship! We are so glad that you have decided to join us in our journey of faith and fellowship. Our goal is to create a community where students can come together to deepen their relationship with God and connect with other believers. We have a variety of activities planned including Bible studies, prayer meetings, worship nights, and service opportunities. These events are designed to help you grow in your faith and provide you with a supportive community during your time at the university. We believe that faith should be an integral part of your university experience and we are committed to providing opportunities for you to explore and deepen your relationship with God. We look forward to getting to know you and supporting you as you navigate your college journey. Please don't hesitate to reach out to us with any questions or if you need any support. We are excited to have you be a part of our fellowship and can't wait to see what God has in store for us. Welcome to the Arba Minch University Christian Fellowship!",
 
  backgroundColor: `url(${welcome})`,
  videoLink: "https://mdbootstrap.com/img/video/Sail-Away.mp4",
};
const welcomeMessage={
title:'Welcome message',
message:"Welcome to the Arba Minch University Christian Fellowship! We are so glad that you have decided to join us in our journey of faith and fellowship. Our goal is to create a community where students can come together to deepen their relationship with God and connect with other believers. We have a variety of activities planned including Bible studies, prayer meetings, worship nights, and service opportunities. These events are designed to help you grow in your faith and provide you with a supportive community during your time at the university. We believe that faith should be an integral part of your university experience and we are committed to providing opportunities for you to explore and deepen your relationship with God. We look forward to getting to know you and supporting you as you navigate your college journey. Please don't hesitate to reach out to us with any questions or if you need any support. We are excited to have you be a part of our fellowship and can't wait to see what God has in store for us. Welcome to the Arba Minch University Christian Fellowship!",

leaderName:'Mekilt tsegaye',
posetion:'main leader',
}
const fellowship = {
  name: "Our Fellowship",

  members: "MEMBERS",
  membersNum: "10K+",
  teamNum: "10+",
  service: "Amharic Service",
  // linear-gradient(to right, #5e72e4, #825ee4, #ac44e4)
  bgColor: "linear-gradient(360deg, rgba(255, 33, 251, 0.45) 0%, rgba(252, 158, 28, 0.3825) 65.09%)",
  
};
const Teams={
  title:'Teams waiting You',
  description:"A groups of people who come together to support and encourage each other in their spiritual journey",
  
}
const program = {
  title: "Regular Programs",
  data: [
    {
      name: "Genearal FellowShip",
      day: "SUN",
      time: "9:00 pm",
      address: "Kalehiwot Church",
      
    },
    {
      name: "11,Batch Programs",
      day: "Wed",
      time: "9:00 pm",
      address: "Kalehiwot Church",
      py: "40px",
      bg: "bg-Cblack",
    },
    {
      name: "11,Batch Programs",
      day: "Wed",
      time: "9:00 pm",
      address: "Kalehiwot Church",
      py: "80px",
      bg: "bg-Cdorenge",
    },
    {
      name: "11,Batch Programs",
      day: "Wed",
      time: "9:00 pm",
      address: "Kalehiwot Church",
      py: "160px",
      bg: "bg-Corenge",
    },
  ],
};
const registerui = {
  title: "Haven't registerd yet?",
 backgroundColor:`rgba(255, 217, 131, 0.21)`,
  description: "Fill the form to be a part of ",
  buttonName: "Register",
  Fname:'First Name',
  Lname:'Last Name',
  Ycity:'Your City',
  Lang:'Language',
  Pnumber:'Phone Number',
  church:'church',
  Sex:'Sex',
  Male:'Male',
  Female:'Female',
  Host:'Is Host Available',
  Yes:'yes',
  No:'No',
  arrivalDate:'Arrival-Date'
};
const aboutCampus={
title:'About Campus',
description:'Develop, implement effective SMIS to collect, process, deploy and disseminate information and data on UG/PG programs, student’s population and activities implement proper mechanisms and instruments for the recruitment, admission, placement and orientation of new UG and  PG students',

}
const city = {
  title: "Our city",
  dress: "Dress",
  DIcon: dress,
  Dlang: language,
  lang: "Language",
  history:
    "Arbaminch is a city located in the southern part of Ethiopia. It is the capital of the Southern Nations, Nationalities,  and Peoples Region and serves as a hub for the surrounding  rural communities. The city is known for its natural beauty,  with the nearby Nechisar National Park and the nearby Lake Abaya and Lake Chamo, which are popular for boat trips and bird watching. Arbaminch is also home to several ethnic groups and cultures, and visitors can experience the traditional way  of life of the Dorze, the Kafficho, and the Gamo people. Additionally, the city offers visitors a great selection of hotels, restaurants, and markets to explore. Arba Minch means 40 Springs, originated from  the presence of more than 40 springs. Arbaminch 434.8 km from Adis-Abeba Arbaminch is a city located in the southern part of Ethiopia. It is the capital of the Southern Nations, Nationalities,  and Peoples Region and serves as a hub for the surrounding  rural communities. The city is known for its natural beauty,  with the nearby Nechisar National Park and the nearby Lake Abaya and Lake Chamo, which are popular for boat trips and bird watching. Arbaminch is also home to several ethnic groups and cultures, and visitors can experience the traditional way  of life of the Dorze, the Kafficho, and the Gamo people. Additionally, the city offers visitors a great selection of hotels, restaurants, and markets to explore Arba Minch  means 40 Springs originated from  the presence of more than 40 springs. Arbaminch 434.8 km from Adis-Abeba",
  Clearsky: Clearsky,
  Fewclouds: Fewclouds,
  Dress: [
    { title: "Hot Session", description: "Shirt with" },
    { title: "Summer Session", description: "Shirt with" },
  ],
  language: [
    { title: "primary", lang: "Gamo" },
    { title: "Secondary", lang: "Amharic" },
  ],
};

const Contact = {
  title: "OUR LEADERS",
  backgroundColor: "bg-green",
 
};
const Gallery = {
  title: "Gallery",
 
};
const Footer = {
  title: "Scoial Medias",
  backgroundColor: "#05419B",
  data: [{ icon: fb }, { icon: tg }, { icon: tk }, { icon: yt }, { icon: ig }],
  about: [
    { name: "About" },
    { name: "Terms & Conditions" },
    { name: "Privacy Policy" },
  ],
 phone:'+0928700105',
 email:'misael@gmail.com',
 maplink:'www.google.com',
 contactus:' contact us!',
 findus:'Find us!',
};


export default {
  welcom,
  welcomeMessage,
  fellowship,
  program,
  registerui,
  city,
  Contact,
  Gallery,
  Footer,
  aboutCampus,
  Teams,
 
};
