import React, { useEffect, useState } from 'react'
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import { useInView } from "framer-motion"
import { useRef } from 'react';
import { BASE_URL } from '../Page/Url';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
function Gallery(props) {
  // AOS.init();
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});
    const [deviceType, setDeviceType] = useState("");
    const [itemsPerPage,setItemPerPage]=useState(2)
  useEffect(() => {
     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
     setDeviceType(isMobile ? "mobile" : "desktop");
     if(deviceType==="mobile"){
       setItemPerPage(2)
       }else{
         setItemPerPage(4)
       }
   }, [deviceType]);
 
    const [sliderRef, instanceRef] = useKeenSlider({
    
      loop: true,
      slides: {
        perView: itemsPerPage,
        spacing: 8
      }
    },
      
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      }
    ]

    );
 
  return (
    <section className='relative mb-[96px]' id="Gallery">
  <div ref={ref} >
    <div className=''>
      {/* top Gallery */}
      <div className=' flex flex-col text-center mt-9 md:pt-20'>
      <p className={`${isView?'translation-all duration-500 scale-500 ':'scale-0'} Poppins Popp-600 text-[24px] md:text-[48px] lg:text-[64px] md:leading-[18px] lg:leading-[60px]`}>{props?.caption?.gallery_Title}</p>
  <p className={`${isView?'translation-all duration-700 scale-500':'scale-0'} Poppins Popp-400 leading-[28px] text-[12px] md:text-[14px] lg:text-[18px] text-[#6B1768] md:mt-[28px] lg:mt-[28px]`}>{props?.caption?.gallery_Subtitle}</p>
      </div>
      {/* md */}
      <div className={`${ props.props?.Gallery_Collection_1.length<4?'md:grid-cols-2' :'md:grid-cols-3 ' }   grid grid-cols-2 lg:grid-cols-5 lg:gap-[32px] justify-end md:mx-[5%]  mt-[40px]`}>
      {
  props.props?.Gallery_Collection_1?.map((data,index)=>{
  return(
    index<3?
     <div key={index} className={` ${ props.props?.Gallery_Collection_1.length%2==0?'  last:col-span-2 md:last:col-span-1 lg:last:col-span-1':''}  hidden md:block lg:hidden md:p-1 first:col-span-2 lg:first:col-span-2 md:first:row-span-2 lg:first:row-span-2 row-span-1 lg:first:w-full lg:first:h-[432px] md:first:w-full md:first:h-[332px] lg:w-full lg:h-[200px] md:w-full md:h-[165px] `}>
     <img  className={`${isView?'translation-all duration-500 scale-500':'scale-0'} w-full h-full object-cover  lg:object-container  md:object-cover`} src={`${BASE_URL}/${data.imageURL}`} alt="" />

   </div>
   :''
  )
     } )
    }   
        </div>
        {/* lg */}
        <div className={`${ props.props?.Gallery_Collection_1.length<=4?'md:grid-cols-2 ' :'md:grid-cols-3 ' } grid grid-cols-2 lg:grid-cols-4 lg:gap-[32px] justify-end md:mx-[5%] lg:mb-0  mt-[40px]`}>
      {
  props.props?.Gallery_Collection_1?.map((data,index)=>{
  return(
       index<=4?
        <div key={index} className={` ${ props.props?.Gallery_Collection_1.length%2==0?'  last:col-span-2 md:last:col-span-1 lg:last:col-span-1':''} hidden md:hidden lg:block  md:p-1 lg:p-0 first:col-span-2 lg:first:col-span-2 md:first:row-span-2 lg:first:row-span-2 row-span-1 lg:first:w-full lg:first:h-[432px] md:first:w-full md:first:h-[332px] lg:w-full lg:h-[200px] md:w-full md:h-[165px] `}>
        <img  className={`${isView?'translation-all duration-500 scale-500':'scale-0'}  w-full h-full object-cover  lg:object-container  md:object-cover`} src={`${BASE_URL}/${data.imageURL}`} alt="" />

      </div>:''
     
  )
   } )
    }   
        </div>
        {/* sm */}
        <div className={`${ props.props?.Gallery_Collection_1.length<=4?'md:grid-cols-2' :'md:grid-cols-3 ' } gap-2 mx-2 grid grid-cols-2 lg:grid-cols-5 lg:gap-[32px] justify-end md:mx-[5%]  mt-[40px]`}>
      {
  props.props?.Gallery_Collection_1?.map((data,index)=>(
    
      <div key={index} className={` ${ props.props?.Gallery_Collection_1.length%2==0?'  last:col-span-2 md:last:col-span-1 lg:last:col-span-1':''} block md:hidden lg:hidden md:p-1 first:col-span-2 lg:first:col-span-2 md:first:row-span-2 lg:first:row-span-2 row-span-1 lg:first:w-full lg:first:h-[432px] md:first:w-full md:first:h-[332px] lg:w-full lg:h-[200px] md:w-full md:h-[165px] `}>
          <img  className={`${isView?'translation-all duration-500 scale-500':'scale-0'} w-full h-full object-cover  lg:object-container  md:object-cover `} src={`${BASE_URL}/${data.imageURL}`} alt="" />

        </div>
     ) )
    }   
        </div>
        <div className='flex flex-col justify-center items-center'>
       { props.props?.Gallery_Collection_2?.length!=0&&   <p className={`${isView?'translation-all duration-500 scale-500 text-center ':'scale-0'} md:w-[572px] md:mt-[30px] text-[12px] Popp-500 mt-5  text-[#667085] md:text-[20px] lg:text-[24px] md:leading-[35px] Poppins  text-center lg:mt-[20px] lg:mb-[50px] lg:w-[572px]`}>{props?.caption?.gallery_Description}
</p>}
        </div>
        <div className='grid  grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 lg:gap-[32px] mx-[5%] md:mx-[5%] lg:mx-[5%] mt-[30px] lg:mt-0 lg:mb-0 mb-[26px]'>
      {
  props.props?.Gallery_Collection_2?.map((data,index)=>(
       
          <img key={index} data-aos="zoom-in-down" className={`${isView?'translation-all duration-500 scale-500':'scale-0'} ' md:p-1 md:w-[403px] w-full h-[300px]   md:h-[300px] lg:w-[100%] lg:h-full  object-cover  lg:object-container  md:object-cover'`} src={`${BASE_URL}/${data?.imageURL}`} alt="" />

     
     ) )
    }   
        </div>
      </div>
    </div>
   </section>
  )
}

export default Gallery
