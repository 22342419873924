import React, { useState } from 'react'
import Fellowship from './Fellowship';
import Ourcity from './Ourcity';
import Weather from './Weather';
import Hero from './Hero';
import Programs from './Programs';
import Registerui from './Registerui';
import Contactui from './Contactui';
import Gallery from './Gallery';
import DataPackage from '../Component/DataPackage';
import Welcom from './Welcom';
import AboutCampus from './AboutCampus';
import FellowTeams from './FellowTeams';
import { useEffect } from 'react';
import {  useParams, Link } from "react-router-dom";
import axios from 'axios';
import Footer from './Footer';
import {BASE_URL} from '../Page/Url'
import { Result, Spin } from 'antd';
import Button from '../Component/Button';
import { v4 as uuidv4 } from 'uuid';
import ComingSoon from './ComingSoon';
export default  function   Campus () {
  const [welcome,setWelcom]=useState(null)
  const [Notification,setNotify]=useState(null)
  const [token,setCrossDomainValue]=useState();
  const { id } = useParams();
  const uuid=uuidv4();
useEffect(()=>{
  if(id!=null&&id!='undefined'){ 
    axios.get(`${BASE_URL}/${id}`)
 .then(res=>{
   const data = res?.data;
  
  if(res.data?.status==='200'){
    setWelcom(data)
  }else{
    setNotify(res?.data?.message)
  }
 }).catch(error=>{
  setNotify(error?.response?.data?.message)
 })
   }
    else {
     }

 
},[])

function Empty(){
    return(
      <div className='flex justify-center items-center h-screen w-screen'>
      <p>{Notification&&
      <Result
      key={uuid}
      status="403"
      title="403"
      subTitle={Notification}
      extra={<Button hrf='register' button='' classname='  text-center mb-[120px] md:mb-0 lg:mb-0 Popp-500  md:text-center lg:text-center rounded-[30px] md:rounded-[80px] lg:rounded-[80px] bg-[#6B1768] md:p-4  lg:p-4 w-[200px] h-[46px] md:h-[58px] md:w-[257px] lg:h-[58px] lg:w-[257px] Poppins  text-white  transform-all duration-300 '>
        <Link to='/' className='flex justify-center gap-8 mt-[-12px] items-center w-full '>
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.9375 23.4375H42.1875C42.6019 23.4375 42.9993 23.6021 43.2924 23.8951C43.5854 24.1882 43.75 24.5856 43.75 25C43.75 25.4144 43.5854 25.8118 43.2924 26.1049C42.9993 26.3979 42.6019 26.5625 42.1875 26.5625H10.9375C10.5231 26.5625 10.1257 26.3979 9.83265 26.1049C9.53962 25.8118 9.375 25.4144 9.375 25C9.375 24.5856 9.53962 24.1882 9.83265 23.8951C10.1257 23.6021 10.5231 23.4375 10.9375 23.4375Z" fill="white"/>
  <path d="M11.5842 25L24.5436 37.9563C24.837 38.2497 25.0018 38.6476 25.0018 39.0625C25.0018 39.4774 24.837 39.8754 24.5436 40.1688C24.2502 40.4622 23.8523 40.627 23.4374 40.627C23.0224 40.627 22.6245 40.4622 22.3311 40.1688L8.26861 26.1063C8.1231 25.9611 8.00766 25.7887 7.92889 25.5989C7.85012 25.409 7.80957 25.2055 7.80957 25C7.80957 24.7945 7.85012 24.591 7.92889 24.4012C8.00766 24.2113 8.1231 24.0389 8.26861 23.8938L22.3311 9.83127C22.6245 9.53787 23.0224 9.37305 23.4374 9.37305C23.8523 9.37305 24.2502 9.53787 24.5436 9.83127C24.837 10.1247 25.0018 10.5226 25.0018 10.9375C25.0018 11.3524 24.837 11.7504 24.5436 12.0438L11.5842 25Z" fill="white"/>
  </svg>
        <p className=''> Back Home</p>
        </Link>
       
        </Button>}
    />
      }</p>
      {!Notification&&<Spin key={uuid} size='large' />}
      </div>
    )
  
}

  return (
    welcome&&welcome?.Registration_Section_Contents?.campusID>-1?
    <div >
    
      <div > 
      
      <Hero registerButton={welcome?.Registration_Form_Visibility} props={welcome?.Welcome_Section_Contents}/>
     
      <Fellowship props={welcome?.Fellowship_Section_Contents}/>
   
       <Welcom  props={welcome?.Intro_Section_Contents}/>
        
  <AboutCampus props={welcome?.About_Section_Contents}/>
  <Ourcity Fewclouds={DataPackage?.city?.Fewclouds} Clearsky={DataPackage?.city?.Clearsky} props={welcome?.City_Section_Contents}/>
 
  <Weather caption={welcome?.Campus_Contents}  props={welcome?.City_Section_Contents} />
 
  <Contactui content={welcome?.Campus_Contents} props={welcome?.Leader_Section_Contents}/>
  
  <div className='flex lg:flex-col flex-col-reverse '>
<FellowTeams caption={welcome?.Campus_Contents} content={welcome?.Campus_Contents} props={welcome?.Team_Section_Contents}/>

<Programs   caption={welcome?.Campus_Contents} props={welcome?.Service_Section_Contents}/> 
</div>
{welcome?.Registration_Form_Visibility!=true?'':
   <Registerui comingsoon={false} key={uuid} caption={welcome} props={DataPackage?.registerui} campusID={welcome?.Welcome_Section_Contents.campusID} fellowname={welcome?.Welcome_Section_Contents} campus={welcome?.Welcome_Section_Contents.title} />
}
<Gallery caption={welcome?.Campus_Contents}  props={welcome}/>
  <Footer lat={parseFloat(welcome?.Footer_Section_Contents?.mapLatitude)} long={parseFloat(welcome?.Footer_Section_Contents?.mapLongitude)} mapSrc={welcome?.mapSrc} mapLink={welcome?.Footer_Section_Contents?.mapLink}   city={welcome?.City_Section_Contents}  props={welcome?.Footer_Section_Contents} social={welcome?.Social_Medias}/>
  </div>
    </div>:
     welcome&&welcome?.Registration_Section_Contents?.campusID<0?
    <ComingSoon key={uuid} caption={welcome} props={DataPackage?.registerui} campusID={welcome&&welcome?.Registration_Section_Contents?.campusID} fellowname={welcome?.Welcome_Section_Contents} />
    :
    <Empty/>
    ) 
  
}
