import axios from "axios";
import { useEffect, useState,useRef } from "react";
import Card from "./Card";
import { Spin } from "antd";
import {BASE_URL} from '../Page/Url'
import { v4 as uuidv4 } from 'uuid';
import LandFooter from '../Page/LandFooter'
import logo192 from '../Assets/logo512.png'
import ReactGA from 'react-ga4'
ReactGA.initialize("G-VP1SF5LD7L");
export default function Allcampus(){
  
    const [searchKey,SetsearchKey]=useState(null);
const [form,setForm] =useState();
const [allcampus,setAllCampus]=useState()
const [page,setPage]=useState(1)
const [progress,setProgress]=useState(false);
const [comingSoon,setComingSoon]=useState([ {
  id: 18,
  campusName: "snfce",
  cityName: "Addis Ababa",
  fellowshipAcronym: "5KECSF",
  fellowshipName: "5 Kilo Evangelical Christian Students Fellowship",
  image: "welcome/8WvIz9r0bXXsaoq6V0KJUBSe5gOjHk7KOElY0pX4.jpg",
  universityName: "Senay University",
  coming:'true',
  url: "https://myfellow.et/coming-soon",
}])
useEffect(()=>{

  function search(searchValue){
setProgress(true);
      axios.post(`${BASE_URL}/api/search-campus?`,
      {
        searchKey:searchValue
      })
      .then(res=>{
       const data = res.data;
      
   
     //setForm(res?.data)
    
     if(res.data?.status===200){ 
       setForm(res?.data?.searchResult)
    
       if(res?.data?.searchResult.length===0){
       // const campus= comingSoon.filter(universityName=>universityName.includes(searchKey))
     setProgress(false)
      
        setForm(null)
       }else{
        setForm(res?.data?.searchResult)
        setProgress(false)
      
       }
       }else{
        setForm(null)
        setProgress(false)
      
      //  console.log(res?.data)
       }
      }).catch(error=>{
      // console.log(error)
      setProgress(false)
      
      })
    }
    search(searchKey)
},[searchKey])
      useEffect(()=>{
       
        axios.post(`${BASE_URL}/api/view-all-campuses?`,
        {
          page:page
        })
        .then(res=>{
           
         if(res?.status===200){
           
            setAllCampus(res?.data)
         }else{
       
         
         }
        }).catch(error=>{
        
        })
      },[page])

      const ref = useRef(null);
     
        const [active,setActive]=useState(1);
        const [scroll, setScroll] = useState(false);
    const [open ,setOpen]=useState(false);
    const url=`${BASE_URL}/api/search-campus?`;
    const DATA={
      Nav:[
      {List:'Home',hrf:'https://myfellow.et'}, 
      ]
    }
    function onAction(url,fellowshipName,image){
     
      ReactGA?.event({
        category: 'visitors of fellowship page',
        action: "allcampus-fellowship-page",
        label: fellowshipName, // optional
        value: 1, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: image, // optional, beacon/xhr/image
      });
      window?.open(url&&url)
    }
    return(
        <div className='flex flex-col justify-center  items-center'> 
        <div  className={`w-[100vw] md:w-full lg:w-full  top-0 z-5 `}>
<div className={`w-[100vw] lg:w-full  top-0 z-50 flex flex-shirnk justify-between items-center px-[5%]  text-[#6B1768] bg-white shadow-sm `}>
<img className=' h-[60px] w-auto py-2' src={logo192}/>
    <div>   
        <ul className='flex gap-[48px]  hidden md:block md:flex lg:block lg:flex lg:items-center lg:gap-[48px]  '>
            <li onClick={()=>setActive(1)} className={`${active===1?'first:text-[#6B1768] first:font-bold Poppins  Popp-900':' hover:font-bold text-[#6B1768] active:font-bold active:Popp-900'}`}> <a className={`cursor-pointer Poppins  ${active===1?'Popp-900':'Popp-500'} `}  href='https://myfellow.et'>Home</a> </li>
            <li onClick={()=>setActive(5)} className={`${active===5?'text-[#6B1768] font-bold Poppins Popp-900 ':' hover:text-[#6B1768] active:font-bold active:Popp-900'}`}> <a className={`cursor-pointer Poppins lg:h-[30px] md:h-[30px] h-[30px] rounded-[50px] w-[100px] md:w-[144px] lg:w-[144px] text-[#CFCFCF] flex justify-center items-center bg-gradient-to-b from-[#5B0F58] to-[#C31091]  ${active===5?'Popp-900':'Popp-500'}`}  target='_black' href='https://admin.myfellow.et'>Login</a> </li>    
        </ul> 
        <div className="flex flex-col justify-end items-end gap-4 duration-300 overflow-hidden ">
<svg onClick={()=>setOpen(!open)} className="block md:hidden  mt-2" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
{open===false?
<path d="M0 0.609741H24V2.67287H0V0.609741ZM0 19.1779H24V21.241H0V19.1779ZM0 6.79913H24V8.86225H0V6.79913ZM0 12.9885H24V15.0516H0V12.9885Z" fill="#6B1768"/>
:<>
<path d="M4.92856 6.34317L17.6565 19.0711L19.0707 17.6569L6.34277 4.92896L4.92856 6.34317Z" fill="#6B1768"/>
<path d="M6.34292 19.071L19.0708 6.34303L17.6566 4.92882L4.92871 17.6567L6.34292 19.071Z" fill="#6B1768"/>

</>

}


</svg>

 <div className={`  flex mb-2 gap-4 items-end block lg:hidden duration-300 overflow-hidden  ${open===true?'transtion-x-0 duration-1000 ':'translate-x-full h-0  duration-300'}`}>
  {DATA.Nav?.map((data,index)=>(
  
<div key={uuidv4()} onClick={()=>setOpen(!open)}  className=''>
    <a className="cursor-pointer " href={data.hrf}>
<p className=''>{data.List} </p>

    </a>
</div>

  ))}
 <a className={`cursor-pointer Poppins lg:h-[30px] md:h-[30px] h-[30px] rounded-[50px] w-[100px] md:w-[144px] lg:w-[144px] text-[#CFCFCF] flex justify-center items-center bg-gradient-to-b from-[#5B0F58] to-[#C31091]  ${active===5?'Popp-900':'Popp-500'}`}  target='_black' href='https://admin.myfellow.et'>Login</a>
       
</div>

</div>
    </div>
    
</div>

</div>
        {/* nav end */}
            {/* contener */}
            <div className=' mx-[27px] md:mx-[94px] lg:w-[1280px] lg:mx-0 mt-[96px]'>
                <div className="flex-col gap-[24px]">
                <p className="Intern Int-600  text-[40px] text-[46px] text-center ">All Campuses</p>
                <p className="Inter Int-400 text-[#6B1768] text-[16px] md:text-[18px] lg:text-[18px] text-center">Try searching your campus here to join a community of like-minded individuals for spiritual growth.</p>
                </div>
                <div className="mt-[40px] flex justify-center items-center">
<div className=" flex justify-start gap-1 md:gap-4 px-4 items-center w-[250px] h-[40px] md:h-[48px] lg:h-[48px] md:w-[320px] lg:w-[320px] searchIng">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  <input onChange={(e)=>SetsearchKey(e.target.value)} onKeyUp={(e)=>SetsearchKey(e.target.value)} onKeyDown={(e)=>SetsearchKey(e.target.value)}  autoComplete="off" type="text" className="px-2 searchAll" placeholder="Search" style={{
       backgroundColor: 'transparent'
  }} />
  {progress&&
  <Spin size='smoll' />
}
</div>
                </div>
         
              <div className="flex flex-wrap justify-center gap-5 mb-[64px] md:gap-[70]  lg:gap-[32px] mt-[40px]">
{form?.map((data,index)=>{

  return(
    <a   target='_blank' onClick={()=>onAction(data.url,data.fellowshipName,`${BASE_URL/data?.image}`)} key={10000+data.id} className=" allcampCard w-[360px] h-[349px] md:w-[384px] md:h-[403px] lg:w-[384px] lg:h-auto">
    <img className={` object-cover w-[360px] md:w-[384px] lg:w-[384px] h-[236px] lg:h-[264px] md:h-[264px]`} src={`${BASE_URL}/${data.image}`}/>
    <div className="mx-[15px] my-[16px] md:mx-[15px] md:my-[32px] lg:mx-[15px] lg:my-[32px] flex flex-col gap-7 md:gap-[12px] lg:gap-[12px]">
        <p className="text-[#6B1768] text-[13px] md:text-[14px] lg:text-[14px] Inter Int-600 ">{data?.campusName}</p>
        <div className="flex flex-col justify-between">
            <p className="text-[20px] md:text-[24px] lg:text-[24px] Inter Int-600">{data?.universityName}</p>
             <p className='Searchfnames  text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] '>{data?.fellowshipName}</p>
        
            <p></p>
        </div>
    </div>
</a>
    )
  })}
  
                </div>
                {!searchKey?.length<0?
                 <div className="flex justify-center items-center"> <Spin size='large' /></div>:<>
                <div className="flex  flex-wrap  items-center gap-5 md:gap-[70]  lg:gap-[32px]  justify-center mb-[64px] ">
                    { allcampus?.searchResult?.map((data,index)=>{
                 
return(!form?.length&&
  <div key={'coming'+index} className="allcampCard">
{data?.url&&
  <a   target='_blank' onClick={()=>onAction(data.url,data.fellowshipName,`${BASE_URL/data?.image}`)}  className="  w-[360px] h-[349px] md:w-[384px] md:h-[403px] lg:w-[384px] lg:h-auto">
  <img className={` object-cover w-[360px] md:w-[384px] lg:w-[384px] h-[236px] lg:h-[264px] md:h-[264px]`} src={`${BASE_URL}/${data.image}`}/>
  <div className="mx-[15px] my-[16px] md:mx-[15px] md:my-[32px] lg:mx-[15px] lg:my-[32px] flex flex-col gap-7 md:gap-[12px] lg:gap-[12px]">
      <p className="text-[#6B1768] text-[20px] text-ellipsis overflow-hidden max-w-[360px]  md:max-w-[384px]  lg:max-w-[384px] md:text-[24px] lg:text-[24px] Inter Int-600 ">{data?.universityName}</p>
      <div className="flex flex-col justify-between">
          <p className="text-[13px] md:text-[14px] lg:text-[14px] Inter Int-600">{data?.campusName}</p>
          <p className='Searchfname text-ellipsis overflow-hidden max-w-[360px]  md:max-w-[384px]  lg:max-w-[384px] text-[14px] md:text-[12px] lg:text-[12px] xl:text-[12px] '>{data?.fellowshipName}</p>
        
          <p></p>
      </div>
  </div>
</a>  
                    } 
  </div>                   
                  )})}
                </div>

               
</>
}
            </div>
            <div className='w-[100%]' >

            <LandFooter/>
</div>
        </div>
    )
}