
import React, { useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import {  useInView } from "framer-motion";
import image from '../Assets/teamBG.png'
import { useRef } from 'react';
import {BASE_URL} from '../Page/Url'
export default function (props) {

const ref =useRef(null);
const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});

    const [data,setData]=useState({
       datas:props.props
    })
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    
    const [deviceType, setDeviceType] = useState("");
    const [itemsPerPage,setItemPerPage]=useState(2)
  useEffect(() => {
     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
     setDeviceType(isMobile ? "mobile" : "desktop");
     if(deviceType==="mobile"){
       setItemPerPage(2)
       }else{
        if(props.props?.length>=2){
          setItemPerPage(2)
        }else{

          setItemPerPage(props.props?.length)
        }
       }
   }, [deviceType]);
 
    const [sliderRef, instanceRef] = useKeenSlider({
      initial: 0,
      slides: {
        perView: itemsPerPage,
        spacing: 8
      },
    
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    });

    const [seeAll,setSeeAll]=useState(3);
  
  return (
      <>
      {props.props!=null&&props.props!='undefined'&&props.props?.length!=0?
    <div ref={ref} 
    style={{
      background:`url(${image})`,
                  backgroundRepeat:'no-repeat',
                  backgroundSize:'cover ',
                  backgroundAttachment: 'fixed',   
                            
    }}
    >
    
        <div 
       
        >
            <div className=' mt-5   flex flex-col items-center justify-center text-center md:text-start  md:mt-0 lg:mb-[94px] md:mb-[116px]'>
                <p className={`${isView?'translation-all duration-500 scale-500':'scale-0'} 'text-[24px] md:text-[48px] lg:text-[64px] md:mt-[94px] lg:mt-[94px] tracking-[-0.72px] Popp-600 Poppins '`}>{props.caption?.teams_Title} </p>
                <p className={` ${isView?'translation-all duration-700 scale-500 md:mt-0 lg:mt-0 mt-0':'scale-0  '} mt-0 md:text-[14px] lg:text-[18px] Poppins Popp-400 lg:mt-0 text-[12px] Poppins Popp-300 text-center md:text-start text-[#6B1768] text-[#6B1768]`}>{props.caption?.teams_Description}</p>
               
            </div>
          
            <div className=' mt-[20px] lg:w-full lg:mt-0 mb-[135px] flex flex-col  justify-center lg:items-center items-end  md:mx-[5%] lg:mx-0' >
      
        <div  className="relative w-full lg:max-w-[95%]  flex flex-wrap items-center justify-center  md:justify-start lg:justify-center  gap-[34px] lg:gap-10 md:gap-8   overflow-hidden  "  >
               {props.props?.map((data,index)=>(

<div key={index}>
{index <seeAll?
<div   className={` ${isView? index%2==0?'duration-700 translation-all  translate-x-0 overflow-hidden relative':'relative overflow-hidden duration-300 translation-all  translate-x-0':'translate-x-96 overflow-hidden'} 'relative rounded-[20px] w-[336.5px] h-[400px] md:w-[320px] md:h-[400px] lg:w-[423px] lg:h-[501px] overflow-hidden'`}>
<div className='  w-[336px] h-[244px] lg:w-[423px]  lg:h-[293px] md:w-[320px] md:h-[220px]'>

<img className='object-cover rounded-t-[20px]  w-[100%]  h-[100%]' src={`${BASE_URL}/${data.image}`} alt="" />


</div>
<div className=' absolute mt-[-60px] rounded-t-[20px] h-full rounded-[20px]    w-full   border-1   bg-[#fff] '  style={{
  
}}>
<div className='w-full  bg-gradient-to-b from-[#c5ccde12] to-[#C5CCDE] h-full '>
  <p data-aos="fade-down" className='font-bold text-[24px] Inter-600 Inter'></p>
  <p data-aos="fade-down" className='font-Inter text-[15px] mt-[24px]'></p>
</div>
</div>
<div className='absolute mt-[-60px] rounded-[20px] h-full md:mb-2'
>
<div className='lg:mt-[20px] md:px-4 mb-[4px] mt-[15px]'>
  <p className='px-[3vw] uppercase md:px-[1vw] font-bold md:text-[20px] lg:text-[24px] font-Inter Inter Int-600 text-[14px] leading-[36px]'>{data?.name}</p>
  <p className='px-[3vw] md:px-[1vw] font-Inter md:text-[15px] lg:text-[15px] md:mt-[10px] lg:mt-[24px] text-[#00000099] Inter Int-400 text-[12px] leading-[20px]'>{data?.description}</p>
</div>
</div>
  </div>
  :''
}
</div>
            ))} 
          
        </div>
        <div className='w-full lg:max-w-[1440px]'>
{props.props?.length>seeAll&&
        <p onClick={()=>setSeeAll(props.props?.length)} className=' cursor-pointer text-right   Popp-700 Poppins text-[#6B1768] md:text-[#000]  lg:text-[#000] text-[15px] lg:text-[20px] md:text-[20px]  mr-[6vw]  md:mx-[5%] lg:mr-[54px] lg:mt-[43px] mt-[20px] md:mt-[43px] font-Poppins  relative '>See All</p>
  }  </div>

            </div>
         
        </div>
   
        
    </div>
  :''
}
</>
  )
}

